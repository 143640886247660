import { UserRoles } from 'config/auth'
import { RoutePaths } from 'config/routes'
import useMyRoles from 'hooks/useMyRoles'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'

export interface AccountMenuLinkType extends ObjectWithTranslationKeyAndRoutePath {
  color?: string
  variant?: string
  role?: string
}

export interface MainMenuLinkType extends ObjectWithTranslationKeyAndRoutePath {
  hasSubmenu?: boolean
}

export const MenuDrawers = {
  accountMenu: 'my-account',
  mainMenu: 'main-menu',
  categoryMenu: 'category-menu',
} as const satisfies Record<string, string>

export const MainMenu = [
  {
    translateKey: 'components.mainMenu.shopYourProducts',
    href: 'category-menu',
    hasSubmenu: true,
  },
  {
    translateKey: 'components.mainMenu.blanketAgreements',
    href: RoutePaths.blanketAgreements,
  },
  {
    translateKey: 'components.mainMenu.myOrders',
    href: RoutePaths.orders,
  },
] as const satisfies ReadonlyArray<MainMenuLinkType>

const AccountMenu = [
  {
    translateKey: 'components.sidebar.orderHistory',
    href: RoutePaths.orders,
  },
  {
    translateKey: 'components.sidebar.recentlyViewed',
    href: RoutePaths.recentlyViewed,
  },
  {
    translateKey: 'components.sidebar.shoppingLists',
    href: RoutePaths.shoppingLists,
  },
  {
    translateKey: 'components.sidebar.blanketAgreements',
    href: RoutePaths.blanketAgreements,
  },
  {
    translateKey: 'components.sidebar.metalSurcharge',
    href: RoutePaths.metalSurcharge,
  },
  {
    translateKey: 'components.sidebar.divider',
    variant: 'divider',
  },
  {
    translateKey: 'components.sidebar.users',
    href: RoutePaths.users,
  },
  {
    translateKey: 'components.sidebar.myProfile',
    href: RoutePaths.profile,
  },
  {
    translateKey: 'components.sidebar.divider',
    variant: 'divider',
    role: UserRoles.KanthalAdmin,
  },
  {
    translateKey: 'components.sidebar.admin',
    href: RoutePaths.admin,
    role: UserRoles.KanthalAdmin,
  },
  {
    translateKey: 'components.sidebar.divider',
    variant: 'divider',
    role: UserRoles.KanthalAdmin,
  },
] as const satisfies ReadonlyArray<AccountMenuLinkType>

// const AccountMenuAdminLink = [] as const satisfies ReadonlyArray<AccountMenuLinkType>

export function useAccountMenu(): ReadonlyArray<AccountMenuLinkType> {
  const capabilities = useBusinessUnitCapabilitites()

  const needsCanPutOrder = [
    'components.sidebar.blanketAgreements',
    'components.sidebar.shoppingLists',
    'components.sidebar.recentlyViewed',
  ]

  // let menuItems = capabilities.canPutOrder
  //   ? AccountMenu
  //   : AccountMenu.filter((x) => !needsCanPutOrder.includes(x.translateKey))

  const userRoles = useMyRoles('businessUnit')

  const menuItems = AccountMenu.filter((x) => {
    if (needsCanPutOrder.includes(x.translateKey) && !capabilities.canPutOrder) {
      return false
    }

    if (!userRoles.some((userRole) => userRole === UserRoles.KanthalAdmin)) {
      return false
    }

    return true
  })

  return menuItems
}
