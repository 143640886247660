import { useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, ReactNode, useRef } from 'react'

import ButtonModal from 'components/elements/ButtonModal'

const ChangeCompanyModal = dynamic(() => import('components/modules/Header/ChangeCompany/ChangeCompanyModal'))

interface ChangeCompanyTriggerProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'ChangeCompanyTrigger'

const ChangeCompanyTrigger: FunctionComponent<ChangeCompanyTriggerProps> = ({ children }) => {
  const btnRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <ButtonModal ref={btnRef} isOpen={isOpen} onClick={onOpen} testid={TAG} variant="link" color="inherit">
        {children}
      </ButtonModal>

      <ChangeCompanyModal
        finalFocusRef={btnRef}
        id={TAG}
        isOpen={isOpen}
        onClose={onClose}
        data-testid={`${TAG}-modal`}
      />
    </>
  )
}

ChangeCompanyTrigger.displayName = TAG

export default ChangeCompanyTrigger
