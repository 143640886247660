/**
 * Returns query filters for product query
 */
function getInitialFilterQuery(blanketAgreementsId: string, otherFilters?: ReadonlyArray<string>) {
  return [
    `variants.attributes.blanketAgreements:"${blanketAgreementsId}"`,
    `variants.scopedPrice.value.centAmount:range (1 to *)`,
    ...(otherFilters ?? []),
  ]
}
export default getInitialFilterQuery
