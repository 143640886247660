import { Box, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import Trans from 'next-translate/Trans'
import React, { FunctionComponent } from 'react'

import Logo from 'components/modules/Header/Logo'

const TAG = 'Info'

const styles = {
  wrapper: {
    gridArea: 'info',
    maxW: '24.5rem',
  },
  description: {
    fontSize: 'md',
    maxW: '500',
    mt: '4',
  },
} as const satisfies SystemStyleInterpolation

const TRANS_COMPONENTS = { strong: <strong /> }

const Info: FunctionComponent = () => (
  <Box {...styles.wrapper}>
    <Logo />
    <Text {...styles.description}>
      <Trans i18nKey="components.footer.description" components={TRANS_COMPONENTS} />
    </Text>
  </Box>
)

Info.displayName = TAG

export default Info
