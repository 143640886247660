import { Box, Divider, Heading, Stack, SystemStyleInterpolation, List, ListItem } from '@chakra-ui/react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import { MAIN_POSTS_PAGE_NAME } from 'config/posts'
import { RoutePaths } from 'config/routes'

/**
 * Used for E2E tests.
 */
const TAG = 'ArticleSidebar'

const styles = {
  aside: {
    display: 'flex',
    flexDirection: 'column',
    w: { base: '100%', md: '33%', lg: 'side-block' },
    minW: 'side-block',
    flex: 1,
    border: '1px solid',
    borderColor: 'gray.100',
    p: '6',
    position: { base: 'static', lg: 'sticky' },
    insetBlockStart: '2rem',
  },
} as const satisfies SystemStyleInterpolation

interface ArticleSidebarProps {
  posts?: PostList[]
  currentPost?: CurrentPost
}

const ArticleSidebar: FunctionComponent<ArticleSidebarProps> = ({ posts, currentPost }) => {
  const { t } = useTranslation()

  if (!posts || !currentPost) {
    return null
  }

  return (
    <Box id={TAG} data-testid={TAG} as="aside" aria-label={t('components.sidebar.ariaLabel')} {...styles.aside}>
      <Box mb="4">
        <Heading as="h2" size="lg" mb="4">
          <Link href={RoutePaths.about}> {t('pages.article.about')}</Link>
        </Heading>
        <Divider />
      </Box>
      <Box as="nav">
        <Stack as={List} direction="column" spacing="2">
          {posts
            .filter(({ filePath }) => !filePath.includes(`${MAIN_POSTS_PAGE_NAME}.mdx`))
            .map((post) => (
              <ListItem color="primary.blue" key={post.filePath}>
                <Link
                  aria-current={post.filePath.replace(/\.mdx?$/, '') === currentPost.slug ? 'page' : undefined}
                  href={`${RoutePaths.about}${post.filePath.replace(/\.mdx?$/, '')}`}
                >
                  {post.variables.title}
                </Link>
              </ListItem>
            ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default ArticleSidebar
