import type { LocalizedString } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'

import { DEFAULT_LANGUAGE } from 'config/i18n.config'

type DisplayLocalizedString = (text: LocalizedString | undefined | null) => string

export function displayInLang(lang: string): DisplayLocalizedString {
  return function display(text) {
    if (!text) {
      return ''
    }

    const firstKey = Object.keys(text)[0]
    return text[lang] || text[DEFAULT_LANGUAGE] || text[firstKey] || ''
  }
}

/**
 * @returns function to display localized string
 *
 * @example
 * ```tsx
 * const productName = masterData.current.name;
 * // productName = { en: 'Product name', de: 'Produktname' }
 *
 * // Let's suppose that the current language is 'en'
 * <Heading as="h1" size="xl">
 *   {display(productName)}
 * </Heading>
 *
 * // This will render
 * // <Heading as="h1" size="xl">
 * //   Product name
 * // </Heading>
 * ```
 */
function useLocalizedString(): DisplayLocalizedString {
  const { lang } = useTranslation()

  return displayInLang(lang)
}

export default useLocalizedString
