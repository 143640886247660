/**
 * The maximum length of a shopping list name.
 * @link https://docs.commercetools.com/api/projects/shoppingLists
 */
export const SHOPPING_LIST_NAME_MAX_LENGTH = 50

/**
 * Schema for checkboxes in the shopping list table.
 */
export interface ShoppingListCheckboxesSchema {
  lineItems: {
    [lineItemId: string]: boolean
  }
}

/**
 * Used as a key for the favorite shopping list in the local storage.
 */
export const SHOPPING_LIST_FAVORITE_LIST_ID = 'shopping-list.favorite'

export enum ShoppingListType {
  Personal = 'personal',
  Shared = 'shared',
}

export const DEFAULT_SHOPPING_LIST_TYPE = ShoppingListType.Personal

export function isValidListType(type: string | undefined): type is ShoppingListType {
  return !!type && Object.values(ShoppingListType).includes(type as ShoppingListType)
}

export const SHOPPING_LIST_LIMIT = 500
