import { Box, Stack, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import CartInfoButton from 'components/modules/Header/TopNavigationBar/CartInfoButton'
import ShoppingListInfoButton from 'components/modules/Header/TopNavigationBar/ShoppingListInfoButton'
import UserInfoButton from 'components/modules/Header/TopNavigationBar/UserInfoButton'
import UserNavigationLoading from 'components/modules/Header/TopNavigationBar/UserNavigationLoading'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'

/**
 * Used for E2E tests.
 */
const TAG = 'UserNavigation'

const styles = {
  stack: {
    direction: 'row',
    spacing: '1',
    align: 'center',
    h: 'full',
  },
  divider: {
    h: '4',
    w: '1px',
    opacity: '0.6',
    bg: 'primary.white',
  },
  lastDivider: {
    h: '4',
    w: '1px',
    opacity: '0.6',
    bg: 'primary.white',
    display: {
      base: 'block',
      lg: 'none',
    },
  },
} as const satisfies SystemStyleInterpolation

const UserNavigation: FunctionComponent = () => {
  const { status } = useAuthState()
  const capabilities = useBusinessUnitCapabilitites()

  if (status === AuthStatus.Loading) {
    return <UserNavigationLoading />
  }

  return (
    <Stack {...styles.stack}>
      {capabilities?.canPutOrder && (
        <>
          <ShoppingListInfoButton />
          <Box {...styles.divider} />
        </>
      )}
      <UserInfoButton />
      <Box {...styles.lastDivider} />
      <CartInfoButton />
    </Stack>
  )
}

UserNavigation.displayName = TAG

export default UserNavigation
