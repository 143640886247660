import { Container, Spinner, VisuallyHidden } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'SectionLoading'

const SectionLoading: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Container
      data-testid={TAG}
      my="10"
      mx="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      width="100%"
      height="full"
    >
      <VisuallyHidden aria-live="polite">{t('components.auth.loading')}</VisuallyHidden>
      <Spinner size="xl" />
    </Container>
  )
}

SectionLoading.displayName = TAG

export default SectionLoading
