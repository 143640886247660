/**
 * Custom form input component
 * @see `src\components\elements\FormInput\index.tsx`
 *
 * @link https://chakra-ui.com/docs/components/form-control
 */
export const Input = {
  baseStyle: {
    defaultProps: {
      variant: 'simple',
    },
    field: {
      h: '12',
      backgroundColor: 'primary.white',
    },
  },
  variants: {
    primary: {
      field: {
        h: '12',
        borderWidth: '1px',
        borderColor: 'gray.100',
        borderRadius: 0,
        fontSize: 'sm',
      },
    },

    filled: {
      field: {
        h: '12',
        backgroundColor: 'gray.50',
        borderWidth: '1px',
        borderColor: 'gray.100!important',
        _disabled: {
          opacity: 1,
          backgroundColor: 'gray.50',
          borderColor: 'gray.100',
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: '0',
        borderColor: 'gray.100',
        fontSize: 'sm',
      },
    },
  },
} as const
