import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode, useEffect } from 'react'

import useMemoCompare from 'hooks/useMemoCompare'
import { GlobalPropsContext, GlobalPropsContextValue } from 'utils/Providers/GlobalProps/context'

interface GlobalPropsProviderProps extends GlobalProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'GlobalPropsProvider'

/**
 * This context stores global props - site settings which you can use across the whole app.
 *
 * @see app.d.ts
 *
 * More information about it in the discussion below:
 * @link https://github.com/vercel/next.js/discussions/10949#discussioncomment-1026197
 */
const GlobalPropsProvider: FunctionComponent<GlobalPropsProviderProps> = ({ children, ...globalProps }) => {
  /**
   * Why `useMemoCompare`? Because we don't want to update context when value is the same but reference changed.
   * Standard `useMemo` would update when nested reference changed, this one checks equality.
   */
  const value = useMemoCompare<GlobalPropsContextValue>(() => globalProps, isEqual)

  const { lang } = useTranslation()
  const { replace, asPath, route } = useRouter()
  useEffect(() => {
    /**
     * TODO: Remove this when Next.js fixes this bug.
     * This is monkey patch for Next.js middleware bug:
     * @link https://github.com/vercel/next.js/issues/43953
     */
    if (
      isEmpty(value) &&
      ((route.includes('404') && lang === 'default') || !route.includes('404')) &&
      !route.includes('500') &&
      !route.includes('maintenance')
    ) {
      replace('/404/')
    }
  }, [asPath, lang, replace, route, value])

  return <GlobalPropsContext.Provider value={value}>{children}</GlobalPropsContext.Provider>
}

GlobalPropsProvider.displayName = TAG

export default GlobalPropsProvider
