import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override link component configuration.
 * @link https://chakra-ui.com/docs/components/link
 * @default `src\node_modules\@chakra-ui\theme\dist\components\link.js`
 */
export const Link: ThemeComponents['Link'] = {
  defaultProps: {
    variant: 'primary',
  },
  baseStyle: {
    color: 'primary.blue',
  },
  variants: {
    primary: {
      _hover: {
        textDecoration: 'underline',
      },
      _focusVisible: {
        outline: 'none',
        textDecoration: 'underline',
      },
    },
    secondary: {
      textDecoration: 'underline',
      color: 'primary.black',
    },
  },
}
