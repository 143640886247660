/**
 * Configuration file for layouts.
 *
 * More details here:
 * @see {GetLayoutPage}
 * @link https://nextjs.org/docs/basic-features/layouts
 */

import getLayoutAdmin from 'components/layouts/Admin'
import getLayoutArticle from 'components/layouts/Article'
import getLayoutAuthorized from 'components/layouts/Authorized'
import getLayoutMain from 'components/layouts/Main'
import getLayoutNotAuthorized from 'components/layouts/NotAuthorized'
import getLayoutWithSidebar from 'components/layouts/WithSidebar'
import { LOGGED_IN_ROUTES, NOT_LOGGED_IN_ROUTES, ADMIN_ROUTES, RoutePaths, RoutePathsValues } from 'config/routes'
import normalizePathname from 'utils/normalizePathname'

const LAYOUT_CONFIG_MAIN = 'Main'
export type LayoutConfigMain = typeof LAYOUT_CONFIG_MAIN
const LAYOUT_CONFIG_AUTHORIZED = 'Authorized'
export type LayoutConfigAuthorized = typeof LAYOUT_CONFIG_AUTHORIZED
const LAYOUT_CONFIG_NOT_AUTHORIZED = 'NotAuthorized'
export type LayoutConfigNotAuthorized = typeof LAYOUT_CONFIG_NOT_AUTHORIZED
const LAYOUT_CONFIG_WITH_SIDEBAR = 'WithSidebar'
export type LayoutConfigWithSidebar = typeof LAYOUT_CONFIG_WITH_SIDEBAR
const LAYOUT_CONFIG_ARTICLE = 'Article'
export type LayoutConfigArticle = typeof LAYOUT_CONFIG_ARTICLE
const LAYOUT_CONFIG_ADMIN = 'AdminLayout'
export type LayoutConfigAdmin = typeof LAYOUT_CONFIG_ADMIN
/**
 * If you want to use _WAY 2_, you should add your layout here
 * and to the switch clause in the below function.
 * Be careful with that, because by adding your layout, you're extending first chunk size.
 */
export const Layout = {
  Main: LAYOUT_CONFIG_MAIN,
  Authorized: LAYOUT_CONFIG_AUTHORIZED,
  NotAuthorized: LAYOUT_CONFIG_NOT_AUTHORIZED,
  WithSidebar: LAYOUT_CONFIG_WITH_SIDEBAR,
  Article: LAYOUT_CONFIG_ARTICLE,
  Admin: LAYOUT_CONFIG_ADMIN,
} as const

const LAYOUT_BASED_ON_ROUTE = {
  // Not logged in routes should be rendered with main layout
  ...Object.values(NOT_LOGGED_IN_ROUTES).reduce<Partial<Record<RoutePathsValues, LayoutConfigNotAuthorized>>>(
    (acc, route) => ({ ...acc, [route]: Layout.NotAuthorized }),
    {},
  ),
  // Logged in routes should be rendered with authorized layout
  ...Object.values({ ...LOGGED_IN_ROUTES, ...ADMIN_ROUTES }).reduce<
    Partial<Record<RoutePathsValues, LayoutConfigAuthorized>>
  >((acc, route) => {
    const layout = route.startsWith(RoutePaths.my)
      ? Layout.WithSidebar
      : route.startsWith(RoutePaths.admin)
      ? Layout.Admin
      : Layout.Authorized
    return { ...acc, [route]: layout }
  }, {}),
}

/**
 * @returns Layout component based on `type`. If `type` is undefined, returns undefined.
 */
function getLayoutByType(type: AppPageLayout['type'] | undefined) {
  switch (type) {
    case Layout.Main:
      return getLayoutMain
    case Layout.Authorized:
      return getLayoutAuthorized
    case Layout.NotAuthorized:
      return getLayoutNotAuthorized
    case Layout.WithSidebar:
      return getLayoutWithSidebar
    case Layout.Article:
      return getLayoutArticle
    case Layout.Admin:
      return getLayoutAdmin
  }
}

/**
 * Get layout for `Component` based on prototype, props or load default main layout.
 *
 * @example
 * ```tsx
 * // WAY 1:
 * // pages/index.tsx
 * import React from 'react'
 * import getLayoutMain from 'components/layouts/Main'
 *
 * const Page: NextPageWithLayout = () => {
 *   return <span>Page content</span>
 * }
 *
 * Page.getLayout = getLayoutMain({})
 *
 * export default Page
 * ```
 *
 * ---
 *
 * @example
 * ```tsx
 * // WAY 2:
 * // pages/index.tsx
 * import type { GetStaticProps } from 'next'
 * import React from 'react'
 * import { Layout } from 'config/layout'
 *
 * const Page: NextPageWithLayout = () => {
 *   return <span>Page</span>
 * }
 *
 * // This way will work out also with `getServerSideProps`
 * export const getStaticProps: GetStaticProps<AppPageLayoutProps> = () => {
 *   return {
 *     props: {
 *       layout: {
 *         type: Layout.Main,
 *         props: {},
 *       },
 *     },
 *   }
 * }
 *
 * export default Page
 * ```
 *
 * ---
 * More details here:
 * @see {GetLayoutPage}
 * @link https://nextjs.org/docs/basic-features/layouts
 *
 * @default LayoutMain
 */
export const getComponentLayout = <PROPS extends AppPageLayoutProps>(
  Component: NextPageWithLayout<PROPS>,
  pageProps: PROPS,
  route: string,
): GetLayout => {
  // Layout by getLayout.
  if (typeof Component.getLayout === 'function') {
    return Component.getLayout
  }

  // Layout by pageProps from `getStaticProps` or `getServerSideProps`.
  const layout = getLayoutByType(pageProps?.layout?.type)
  if (layout) {
    return layout(pageProps.layout?.props)
  }

  // Layout by pathname
  for (const path in LAYOUT_BASED_ON_ROUTE) {
    if (path === normalizePathname(`/${route}/`)) {
      const type = LAYOUT_BASED_ON_ROUTE[path as keyof typeof LAYOUT_BASED_ON_ROUTE]
      const layout = getLayoutByType(type)
      if (layout) {
        return layout(pageProps.layout?.props)
      }
    }
  }

  // Layout default.
  return getLayoutMain(pageProps?.layout?.props)
}
