import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { UserRoles, UserRolesValues } from 'config/auth'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

export type Level = 'customer' | 'businessUnit'

function filterRoles(role: unknown): role is UserRolesValues {
  return !!role && typeof role === 'string' && Object.values(UserRoles).includes(role as UserRoles)
}

/**
 * @returns The roles of the currently logged in user.
 *
 * @param level The level of the role to check.
 * Roles are defined on two levels:
 * - Customer: the role of the customer itself.
 * - Business Unit: the role of the customer in the business unit.
 */
function useMyRoles(level: Level): UserRoles[] {
  const { status, customer } = useAuthState()
  const businessUnit = useBusinessUnit()

  if (status === AuthStatus.Authenticated) {
    const customerInBU = businessUnit.data.current?.associates?.find((item) => item.customer.id === customer?.id)
    const customerRolesInBU = customerInBU?.associateRoleAssignments?.map((item) => item.associateRole?.key) ?? []

    const customerRole = [customer?.custom?.fields?.role]

    return (level === 'customer' ? customerRole : customerRolesInBU).filter(filterRoles)
  }

  return []
}

export default useMyRoles
