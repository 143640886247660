import type { BusinessUnit, Cart, CartPagedQueryResponse } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'
import partition from 'lodash/partition'

import getProductsByIds from 'commercetools/products/getProductsByIds'
import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

type QueryKey = [queryFnName: string, businessUnit?: BusinessUnit]

type CartByBUKey = Pick<QueryFunctionContext<QueryKey>, 'signal' | 'queryKey'>

const CART_AND_ORDER_CUSTOM_TYPE_KEY = 'cartAndOrderType'

/**
 * @returns user cart with business unit key
 * @link https://docs.commercetools.com/api/projects/carts#get-cart
 */
async function getCartByBUKey({ queryKey: [, businessUnit] }: CartByBUKey): Promise<Cart> {
  if (!businessUnit?.key || !businessUnit?.custom?.fields.erpCurrency) {
    throw new Error(`Business Unit is not provided when fetching cart`)
  }

  const blanketAgreementId = businessUnit.custom?.fields.erpBlanketAgreements?.[0]
  const stringifiedParams = stringifyObjectValues({ businessUnitKey: businessUnit.key })
  const params = new URLSearchParams(stringifiedParams).toString()
  const url = `${RoutePaths.cartsApi}?${params}`

  const carts = await apiAuthFetch<CartPagedQueryResponse>(url, { credentials: 'include' })
  let cart: Cart | undefined = carts.results[0]

  /*
   * Blanket agreement be replaced with a new one, we have to check if existing cart matches current blanket agreement.
   * If not, the old cart should be deleted and a new cart should be created.
   */
  if (cart && cart.customerGroup?.id !== blanketAgreementId) {
    await apiAuthFetch<void>(`${RoutePaths.cartsApi}/${cart.id}?${params}&version=${cart.version}`, {
      method: 'DELETE',
      credentials: 'include',
    })

    cart = undefined
  }

  if (!cart) {
    cart = await apiAuthFetch<Cart>(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        currency: businessUnit.custom?.fields.erpCurrency,
        businessUnit: { typeId: 'business-unit', key: businessUnit.key },
        customerGroup: { typeId: 'customer-group', id: blanketAgreementId },
        custom: { type: { typeId: 'type', key: CART_AND_ORDER_CUSTOM_TYPE_KEY } },
      }),
    })
  }

  const productIds = cart.lineItems.map((item) => item.productId)

  const products = await getProductsByIds(
    businessUnit.key,
    productIds,
    businessUnit.custom?.fields.erpBlanketAgreements?.[0],
    businessUnit.custom?.fields.erpCurrency,
  )

  const cartLineItems = cart.lineItems.map((item) => ({
    ...item,
    product: products.body.results.find((product) => product.id === item.productId),
  }))

  const [lineItems, lineItemsWithoutProduct] = partition(cartLineItems, (item) => item.product)

  if (lineItemsWithoutProduct.length > 0) {
    try {
      await apiAuthFetch<Cart>(`${RoutePaths.cartsApi}/${cart.id}?${params}`, {
        method: 'POST',
        body: JSON.stringify({
          actions: lineItemsWithoutProduct.map(({ id }) => ({
            action: 'removeLineItem',
            lineItemId: id,
          })),
          version: cart.version,
        }),
      })
    } catch (error) {
      console.log('error:', error)
    }
  }

  return { ...cart, lineItems }
}

export default getCartByBUKey
