import hasProperty from 'utils/hasProperty'

/**
 * @returns true if error means that the user is not logged in
 * That error is thrown by MSAL Azure AD B2C
 */
function isUserNotLoggedInError(error: unknown): boolean {
  return !!(
    error &&
    hasProperty(error, 'message') &&
    typeof error.message === 'string' &&
    error.message.startsWith('interaction_required')
  )
}

export default isUserNotLoggedInError
