import { Icon, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import CartIcon from 'assets/cart.svg?component'
import Link from 'components/elements/Link'
import { RoutePaths } from 'config/routes'
import useCart from 'utils/Providers/Cart/useCart'

/**
 * Used for E2E tests.
 */
const TAG = 'CartInfoButton'

const styles = {
  link: {
    color: 'primary.white',
    display: { base: 'flex', lg: 'none' },
    alignItems: 'center',
    textDecoration: 'none',
    paddingInline: '3',
    h: 'full',
  },
  icon: {
    boxSize: '1rem',
  },
  text: {
    fontSize: 'md',
    marginInlineStart: '2',
    fontWeight: 'bold',
  },
} as const satisfies SystemStyleInterpolation

const CartInfoButton: FunctionComponent = () => {
  const { t } = useTranslation()

  const { data: cart } = useCart()
  const count = cart?.lineItems?.length || 0

  const label = t('components.header.itemsInCart', { count })

  return (
    <Link {...styles.link} data-testid={TAG} rel="noreferrer" href={RoutePaths.cart} aria-label={label}>
      <Icon aria-hidden="true" as={CartIcon} {...styles.icon} />
      {count ? <Text {...styles.text}>{count}</Text> : null}
    </Link>
  )
}

CartInfoButton.displayName = TAG

export default CartInfoButton
