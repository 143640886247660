import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useMemo } from 'react'

import Error from 'components/elements/Error'
import { RoutePaths } from 'config/routes'

/**
 * Used for E2E tests.
 */
const TAG = 'Unauthenticated'

/**
 * Time in milliseconds to automatically redirect user to login page.
 */
const AUTO_REDIRECT_TIME = 10_000

const Unauthenticated: FunctionComponent = () => {
  const { asPath, push } = useRouter()

  const url = useMemo<string>(() => {
    const url = new URL(process.env.NEXT_PUBLIC_SITE_URL + RoutePaths.login)
    url.searchParams.set('redirect', asPath)
    return url.toString().replace(process.env.NEXT_PUBLIC_SITE_URL, '')
  }, [asPath])

  /**
   * Auto redirect to login page after {@see AUTO_REDIRECT_TIME} seconds.
   */
  useEffect(() => {
    const timer = setTimeout(() => push(url), AUTO_REDIRECT_TIME)

    return () => {
      clearTimeout(timer)
    }
  }, [push, url])

  return (
    <Error
      title="Unauthenticated"
      description="You need to login to access this page. You will be redirected in 10 seconds."
      buttonText="Login to continue"
      buttonURL={url}
    />
  )
}

Unauthenticated.displayName = TAG

export default Unauthenticated
