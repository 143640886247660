import { useQuery } from '@tanstack/react-query'
import React, { FunctionComponent, ReactNode, useMemo } from 'react'

import getCartByBUKey from 'commercetools/cart/getCartByBUKey'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useMSALSessionStatus from 'components/modules/Auth/hooks/useMSALSession'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'
import { CartContext, CartContextValue } from 'utils/Providers/Cart/context'

interface CartProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'CartProvider'

const CartProviderInner: FunctionComponent<CartProviderProps> = ({ children }) => {
  const msalStatus = useMSALSessionStatus()
  const businessUnit = useBusinessUnit()

  const response = useQuery(['getCartByBUKey', businessUnit.data.current], getCartByBUKey, {
    enabled: msalStatus === AuthStatus.Authenticated && !!businessUnit.data.current?.key,
  })

  const value = useMemo<CartContextValue>(
    () =>
      ({
        data: response.data,
        isSuccess: response.isSuccess,
        isLoading: response.isLoading,
        isError: response.isError,
        refetch: response.refetch,
      } as CartContextValue),
    [response.data, response.isError, response.isLoading, response.isSuccess, response.refetch],
  )

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

const CartProvider: FunctionComponent<CartProviderProps> = ({ children }) => {
  const businessUnitCapabilitites = useBusinessUnitCapabilitites()

  if (businessUnitCapabilitites?.canPutOrder) {
    return <CartProviderInner>{children}</CartProviderInner>
  } else {
    return <>{children}</>
  }
}

CartProvider.displayName = TAG

export default CartProvider
