import type { ClientResponse, Customer, MyCustomerUpdateAction } from '@commercetools/platform-sdk'

import { CT_MY_CLIENT } from 'commercetools/client/user'

/**
 * @returns updated current customer
 * @link https://docs.commercetools.com/api/projects/customer
 */

function updateMyAccount(action: MyCustomerUpdateAction | MyCustomerUpdateAction[]): Promise<ClientResponse<Customer>> {
  return CT_MY_CLIENT.me()
    .get()
    .execute()
    .then((response) =>
      CT_MY_CLIENT.me()
        .post({
          body: {
            version: response.body.version,
            actions: Array.isArray(action) ? action : [action],
          },
        })
        .execute(),
    )
}

export default updateMyAccount
