import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Badge from 'components/elements/Badge'
import useCart from 'utils/Providers/Cart/useCart'

/**
 * Used for E2E tests.
 */
const TAG = 'CartBadge'

const CartBadge: FunctionComponent = () => {
  const { t } = useTranslation()

  const { data: cart } = useCart()
  const count = cart?.lineItems.length || 0

  return <Badge id={TAG} data-testid={TAG} aria-label={t('components.header.itemsInCart', { count })} count={count} />
}

CartBadge.displayName = TAG

export default CartBadge
