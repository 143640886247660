import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override alert component configuration.
 * @link https://chakra-ui.com/docs/components/alert
 * @default `src\node_modules\@chakra-ui\theme\dist\components\alert.js`
 */
export const Alert = {
  variants: {
    'left-accent': {
      wrapper: {
        borderColor: 'primary.blue',
        bg: 'rgba(12, 90, 122, 0.1)',
      },
    },
  },
  baseStyle: {
    icon: {
      alignSelf: 'flex-start',
    },
  },
} satisfies ThemeComponents['Alert']
