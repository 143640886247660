import type { ClientResponse, ShoppingList, ShoppingListPagedQueryResponse } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'

import { CT_MY_CLIENT } from 'commercetools/client/user'
import getProductsByIds from 'commercetools/products/getProductsByIds'
import { RoutePaths } from 'config/routes'
import { SHOPPING_LIST_LIMIT } from 'config/shoppingList'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

type QueryKey = [queryFnName: string, businessUnitKey: string, blanketAgreementId: string, currency: string]

type MyShoppingLists = Pick<QueryFunctionContext<QueryKey>, 'signal' | 'queryKey'>

/**
 * @returns my and BU shopping lists
 * @link https://docs.commercetools.com/api/projects/me-shoppingLists
 */

const filterOutProductWithoutPriceFromLists = (
  businessUnitKey: string,
  uniqueShoppingLists: ShoppingList[],
  blanketAgreementId: string,
  currency: string,
) => {
  const promises = uniqueShoppingLists.map(async (shoppingList) => {
    const productIds = shoppingList.lineItems.map((item) => item.productId)

    const products = await getProductsByIds(businessUnitKey, productIds, blanketAgreementId, currency)

    return {
      ...shoppingList,
      lineItems: shoppingList.lineItems
        .map((item) => ({
          ...item,
          product: products.body.results.find((product) => product.id === item.productId),
        }))
        .filter(({ product }) => product),
    }
  })
  return Promise.all(promises)
}

async function getMyShoppingLists({
  signal,
  queryKey: [, businessUnitKey, blanketAgreementId, currency],
}: MyShoppingLists): Promise<ShoppingListPagedQueryResponse> {
  const stringifiedParams = stringifyObjectValues({
    expand: 'lastModifiedBy.customer',
    limit: SHOPPING_LIST_LIMIT,
    where: ['custom(fields(type = "shared"))', `custom(fields(businessUnitKey="${businessUnitKey}"))`],
    businessUnitKey,
  })

  const params = new URLSearchParams(stringifiedParams).toString()

  const url = `${RoutePaths.shoppingListApi}?${params}`

  const shoppingLists = await Promise.all([
    apiAuthFetch<ClientResponse<ShoppingListPagedQueryResponse>>(url),

    CT_MY_CLIENT.me()
      .shoppingLists()
      .get({
        queryArgs: {
          expand: 'customer',
          limit: SHOPPING_LIST_LIMIT,
          where: [
            'custom(fields(type = "personal" or type is not defined))',
            `custom(fields(businessUnitKey="${businessUnitKey}"))`,
          ],
        },
      })
      .execute({ signal }),
  ])
  const uniqueShoppingLists = uniqBy(
    shoppingLists.reduce<ShoppingList[]>((acc, { body }) => [...acc, ...body.results], []),
    (list) => list.id,
    // Sort by `createdAt` DESC to get the latest shopping lists first
  ).sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1))

  // We need show number products inside list, unfortunately we need verify if product still exist and have price
  const shoppingListWithProducts = await filterOutProductWithoutPriceFromLists(
    businessUnitKey,
    uniqueShoppingLists,
    blanketAgreementId,
    currency,
  )

  return {
    total: uniqueShoppingLists.length,
    count: uniqueShoppingLists.length,
    offset: 0,
    limit: SHOPPING_LIST_LIMIT,
    results: shoppingListWithProducts,
  }
}

export default getMyShoppingLists
