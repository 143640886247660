import { Box, Icon, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import LogoIcon from 'assets/logo.svg?component'
import Link from 'components/elements/Link'
import { SITE_TITLE } from 'config/seo'
import normalizePathname from 'utils/normalizePathname'

/**
 * Used for E2E tests.
 */
const TAG = 'Logo'

const styles = {
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    minW: '0',
    h: 'full',
    maxH: {
      base: '40px',
      lg: '48px',
    },
  },
  icon: {
    width: { base: 134, lg: 216 },
    height: 'auto',
    maxW: 'full',
  },
} as const satisfies SystemStyleInterpolation

const Logo: FunctionComponent = () => {
  const { lang } = useTranslation()
  const homeUrl = normalizePathname(`/${lang}/`)

  return (
    <Box h="auto">
      <Link {...styles.link} rel="noreferrer" href={homeUrl} aria-label={SITE_TITLE} data-testid={TAG}>
        <Icon aria-hidden="true" as={LogoIcon} {...styles.icon} />
      </Link>
    </Box>
  )
}

Logo.displayName = TAG

export default Logo
