import type { ClientResponse } from '@commercetools/platform-sdk'

/**
 * When `when` query is empty, return empty response.
 */
export const EMPTY_CLIENT_RESPONSE_PAGED = {
  body: {
    count: 0,
    limit: 20,
    offset: 0,
    results: [],
    total: 0,
  },
  statusCode: 200,
} satisfies ClientResponse
