/**
 * @link https://chakra-ui.com/docs/components/select/
 */
export const Select = {
  baseStyle: {
    field: {
      _hover: {
        cursor: 'pointer',
      },
    },
  },
  sizes: {
    md: {
      icon: {
        fontSize: 'md',
        color: 'primary.blue',
      },
      field: {
        minHeight: '12',
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: 'none',
        borderWidth: 1,
        borderColor: 'gray.100',
        backgroundColor: 'white',
      },
    },
  },
}
