import type { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, ReactNode, useEffect, useReducer, useState } from 'react'

import getMe from 'commercetools/customer/getMe'
import { AuthInitContext, AuthStatus, initialValue } from 'components/modules/Auth/AuthInit/context'
import authReducer, { ACTION_LOGIN, ACTION_LOGOUT } from 'components/modules/Auth/AuthInit/reducer'
import useMSALSessionStatus from 'components/modules/Auth/hooks/useMSALSession'
import setStateInHtml from 'components/modules/Auth/utils/setStateInHtml'

interface AuthInitProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'AuthInit'

/**
 * Regex for checking if user is logged in or not. It's used in storage listener.
 */
const REGEX_MSAL_ACTIVE_ACCOUNT = /^msal\.(.+)\.active-account$/

/**
 * Our wrapper for MSAL and Commercetools.
 *
 * To get the current user, customer and status, please use @see useAuthState hook.
 * @example
 * ```ts
 * const { customer, user, status } = useAuthState()
 * ```
 */
const AuthInit: FunctionComponent<AuthInitProps> = ({ children }) => {
  const msal = useMsal()
  const user = msal.instance.getActiveAccount()
  const msalStatus = useMSALSessionStatus()

  const [value, dispatch] = useReducer<typeof authReducer>(authReducer, initialValue)

  const { data, isSuccess, isError } = useQuery(['getMe'], getMe, {
    enabled: msalStatus === AuthStatus.Authenticated,
  })

  useEffect(() => {
    if (isSuccess && msalStatus === AuthStatus.Authenticated) {
      dispatch({ type: ACTION_LOGIN, payload: { customer: data.body, user: user as AccountInfo } })
      setStateInHtml(true)
    }
    /**
     * We don't want to add `user` to the dependencies array,
     * because it's a reference to the object and it will change on every render.
     * We are also sure that `user` is defined when `msalStatus` is `Authenticated`.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalStatus, data, isSuccess])

  useEffect(() => {
    if (isError || msalStatus === AuthStatus.Unauthenticated) {
      dispatch({ type: ACTION_LOGOUT })
      setStateInHtml(false)
    }
  }, [msalStatus, isError])

  /**
   * Handles changes in localStorage.
   * That will be invoked e.g. when user logs in/out in another tab.
   * We could use BroadcastChannel API, but that solution is simpler.
   */
  const [, setForceUpdate] = useState({})
  useEffect(() => {
    const onStorageChange = (event: StorageEvent): void => {
      if (event.key && REGEX_MSAL_ACTIVE_ACCOUNT.test(event.key)) {
        // Thanks to that, we will force re-render of this component
        // So `instance.getActiveAccount()` and `useMSALSessionStatus()` will return the new value.
        setForceUpdate({})
      }
    }

    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])

  return <AuthInitContext.Provider value={value}>{children}</AuthInitContext.Provider>
}

AuthInit.displayName = TAG

export default AuthInit
