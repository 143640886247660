import type { ErrorObject } from '@commercetools/platform-sdk'

function isCommercetoolsSDKError(error: unknown): error is ErrorObject {
  return typeof error === 'object' && !!error && 'statusCode' in error && 'name' in error && 'originalRequest' in error
}

function isCommercetoolsError(error: unknown): error is ErrorObject {
  return (
    typeof error === 'object' &&
    !!error &&
    'errors' in error &&
    Array.isArray(error.errors) &&
    !!error.errors[0] &&
    'message' in error
  )
}

function getErrorCode(error: unknown): string {
  if (typeof error === 'object' && error && 'body' in error) {
    if (isCommercetoolsError(error.body)) {
      return error.body.errors[0].code
    }
  }
  if (isCommercetoolsSDKError(error)) {
    return error.name
  }
  if (isCommercetoolsError(error)) {
    return error.errors[0].code
  }
  return ''
}

/**
 * Commercetools errors that should not be retried by the useQuery hook.
 * @link https://docs.commercetools.com/api/errors
 */
const ONE_RETRY_ERRORS_COMMERCETOOLS = [
  'ConcurrentModification',
  'InvalidOperation',
  'InvalidJsonInput',
  'InvalidField',
  'InternalConstraintViolated',
  'ObjectNotFound',
  'ResourceNotFound',
  'RequiredField',
]

export function isCommercetoolsOneTryError(error: unknown): boolean {
  const code = getErrorCode(error)
  return ONE_RETRY_ERRORS_COMMERCETOOLS.includes(code)
}

export default isCommercetoolsSDKError
