import { Divider } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import Link from 'components/elements/Link'
import type { AccountMenuLinkType } from 'config/menu'

/**
 * Used for E2E tests.
 */

const SidebarLink: FunctionComponent<AccountMenuLinkType> = ({ translateKey, href, variant, color }) => {
  const { asPath } = useRouter()
  const { t } = useTranslation()

  const isCurrentPage = asPath.startsWith(href)
  const ariaCurrent = isCurrentPage ? 'page' : undefined

  switch (variant) {
    case 'divider':
      return <Divider />
    default:
      return (
        <Link aria-current={ariaCurrent} href={href} color={color}>
          {t(translateKey)}
        </Link>
      )
  }
}

export default SidebarLink
