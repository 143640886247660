import Router from 'next/router'
import Trans from 'next-translate/Trans'
import React, { PureComponent, ReactNode } from 'react'

import Error from 'components/elements/Error'

/**
 * Used for E2E tests.
 */
const TAG = 'ErrorBoundary'

interface ErrorBoundaryState {
  hasError: boolean
  route: string
}

interface ErrorBoundaryProps {
  children: ReactNode
}

class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      route: '',
    }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true, route: Router.route }
  }

  componentDidUpdate(): void {
    if (this.state.hasError && this.state.route !== Router.route) {
      this.setState({ hasError: false, route: '' })
    }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(`[${TAG}]`, error)
    console.error(`[${TAG}]`, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error
          title={<Trans i18nKey="pages._error.title" />}
          description={<Trans i18nKey="pages._error.description" />}
          buttonText={<Trans i18nKey="pages._error.button" />}
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
