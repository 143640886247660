import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { MenuDrawerContext, MenuDrawerContextValue } from 'components/modules/Header/MobileMenu/Provider/context'
import { MenuDrawers } from 'config/menu'

interface MenuDrawerProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'MenuDrawerProvider'

const MenuDrawerProvider: FunctionComponent<MenuDrawerProviderProps> = ({ children }) => {
  const [currentMenu, setCurrentMenu] = useState<string>('')
  const router = useRouter()

  const handleBackToMenu = useCallback(() => {
    setCurrentMenu(MenuDrawers.mainMenu)
  }, [setCurrentMenu])

  const handleClose = useCallback(() => {
    setCurrentMenu('')
  }, [setCurrentMenu])

  useEffect(() => {
    if (currentMenu !== '') {
      router.events.on('routeChangeStart', handleClose)
      return () => {
        router.events.off('routeChangeStart', handleClose)
      }
    }
  }, [currentMenu, handleClose, router.events])

  const value = useMemo<MenuDrawerContextValue>(
    () =>
      ({
        currentMenu,
        setCurrentMenu,
        backToMainMenu: handleBackToMenu,
        closeMenu: handleClose,
      } as MenuDrawerContextValue),
    [currentMenu, handleBackToMenu, handleClose],
  )

  return <MenuDrawerContext.Provider value={value}>{children}</MenuDrawerContext.Provider>
}

MenuDrawerProvider.displayName = TAG

export default MenuDrawerProvider
