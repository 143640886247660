import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override checkbox component configuration.
 * @link https://chakra-ui.com/docs/components/checkbox
 * @default `src\node_modules\@chakra-ui\theme\dist\components\checkbox.js`
 */
export const Checkbox: ThemeComponents['Checkbox'] = {
  baseStyle: {
    wrapper: {
      minHeight: '6',
      minWidth: '10',
      userSelect: 'none',
      wordBreak: 'break-word',
      // Input without label (only checkbox with aria-label)
      '&:not(:has(span:nth-of-type(2)))': {
        justifyContent: 'center',
        '&:hover span::before': {
          // We don't need to specify here :focus-visible, because label will never be focused
          transform: 'translate(-50%, -50%) scale(1)',
        },
      },
    },
    control: {
      height: '4',
      width: '4',
      borderColor: 'gray.400',
      borderWidth: '1px',
      borderRadius: 'none',
      bg: 'primary.white',
      position: 'relative',
      _before: {
        position: 'absolute',
        width: '10',
        height: '10',
        background: 'gray.100',
        top: '50%',
        left: '50%',
        content: '""',
        display: 'block',
        zIndex: '-1',
        borderRadius: '50%',
        transformOrigin: 'center',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.2s',
      },
      _hover: {
        _before: {
          transform: 'translate(-50%, -50%) scale(1)',
        },
      },
      _focusVisible: {
        boxShadow: 'none',
        outline: 'none',
        _before: {
          transform: 'translate(-50%, -50%)  scale(1)',
        },
      },
      _checked: {
        bg: 'white',
        borderColor: 'black',
        color: 'red',
        _hover: {
          bg: 'white',
        },
        _disabled: {
          borderColor: 'gray.300',
          bg: 'gray.300',
          color: 'red',
        },
      },
      _indeterminate: {
        bg: 'white',
        borderColor: 'black',
        color: 'red',
      },
      _disabled: {
        borderColor: 'gray.300',
        bg: 'gray.300',
        color: 'red',
        _before: {
          content: 'none',
        },
      },
    },
  },

  sizes: {
    md: {
      label: {
        width: 'full',
        display: 'flex',
        fontSize: 'sm',
      },
    },
  },
}
