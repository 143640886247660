import type { Reducer } from 'react'

import { Authenticated, AuthInitContextValue, AuthStatus } from 'components/modules/Auth/AuthInit/context'

export type AuthState = AuthInitContextValue

export const ACTION_LOGIN = 'AUTH_LOGIN'
interface AuthActionLogin {
  type: typeof ACTION_LOGIN
  payload: Omit<Authenticated, 'status'>
}

export const ACTION_LOGOUT = 'AUTH_LOGOUT'
interface AuthActionLogout {
  type: typeof ACTION_LOGOUT
}

export const ACTION_LOADING = 'AUTH_LOADING'
interface AuthActionLoading {
  type: typeof ACTION_LOADING
}

export type AuthActions = AuthActionLogin | AuthActionLogout | AuthActionLoading

/**
 * Auth reducer. Handles login, logout and loading actions.
 */
const authReducer: Reducer<AuthState, AuthActions> = (state, action) => {
  switch (action.type) {
    case ACTION_LOADING:
      if (state.status === AuthStatus.Loading) {
        // Prevent setting the same state multiple times
        return state
      }
      return {
        ...state,
        status: AuthStatus.Loading,
      }
    case ACTION_LOGIN:
      return {
        ...state,
        user: action.payload.user,
        customer: action.payload.customer,
        status: AuthStatus.Authenticated,
      }
    case ACTION_LOGOUT:
      return {
        ...state,
        user: null,
        customer: null,
        status: AuthStatus.Unauthenticated,
      }
    default:
      return state
  }
}

export default authReducer
