import { Box, Container, Stack, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import Navigation from 'components/modules/Header/NavigationBar/Navigation'

/**
 * Used for E2E tests.
 */
const TAG = 'NavigationBar'

const styles = {
  nav: {
    display: { base: 'none', lg: 'flex' },
    h: '12',
    bg: 'gray.50',
    boxShadow: 'md',
  },
  wrapper: {
    spacing: '4',
    direction: 'row',
    w: 'full',
    h: 'full',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
} as const satisfies SystemStyleInterpolation

/**
 * Extracted to component to prevent unnecessary re-renders.
 * @see https://kentcdodds.com/blog/optimize-react-re-renders
 */
const NavigationBarWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Box aria-label={t('components.lowerBar.label')} data-testid={TAG} as="nav" {...styles.nav}>
      {children}
    </Box>
  )
}

const NavigationBar: FunctionComponent = () => (
  <NavigationBarWrapper>
    <Stack as={Container} {...styles.wrapper}>
      <Navigation />
    </Stack>
  </NavigationBarWrapper>
)

NavigationBar.displayName = TAG

export default NavigationBar
