import { Stack } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import isRootCategory from 'commercetools/utils/isRootCategory'
import CategoryMenuButton from 'components/modules/Header/MobileMenu/CategoryMenuContent/CategoryMenuButton'
import MobileMenuDrawer from 'components/modules/Header/MobileMenu/MobileMenuDrawer'
import { MenuDrawers } from 'config/menu'
import useInitialFacets from 'hooks/useInitialFacets'

/**
 * Used for E2E tests.
 */
const TAG = 'CategoryMenuContent'

const CategoryMenuContent: FunctionComponent = () => {
  const { categories } = useInitialFacets()

  return (
    <MobileMenuDrawer name={MenuDrawers.categoryMenu}>
      <Stack px="10" py="4" spacing="4">
        {categories?.filter(isRootCategory)?.map((category) => (
          <CategoryMenuButton key={category.id} category={category} />
        ))}
      </Stack>
    </MobileMenuDrawer>
  )
}

CategoryMenuContent.displayName = TAG
export default CategoryMenuContent
