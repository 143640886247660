import { locales } from 'i18n'

export const DEFAULT_LANGUAGE = 'en'

/**
 * List of supported locales w/o 'default' locale.
 */
export const LOCALES = locales.filter((locale) => locale !== 'default')

/**
 * Default currency code for e.g. carts
 */
export const DEFAULT_CURRENCY = 'USD'
