import { rangeSliderFilters } from 'config/product'

/**
 * Returns range facets as array of strings for commercetools
 */
function getRangeFacetFilters() {
  return Object.values(rangeSliderFilters)
    .flatMap((filter) => filter)
    .map((attr) => `variants.attributes.${attr}:range(0 to *)`)
}

export default getRangeFacetFilters
