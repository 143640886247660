import { Button, SystemStyleInterpolation } from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import getCategoryImageByName from 'components/modules/Products/utils/getCategoryImageByName'
import { RoutePaths } from 'config/routes'
import type { FacetCategoriesProps } from 'hooks/useInitialFacets'
import useLocalizedString from 'hooks/useLocalizedString'
import assignParamsToUrl from 'utils/assignParamsToUrl'

interface CategoryMenuButtonProps {
  category: FacetCategoriesProps
}

const DEFAULT_WH = 48

export const categoryButtonStyles = {
  button: {
    variant: 'unstyled',
    display: 'flex',
    fontSize: 'xl',
    color: 'primary.black',
    justifyContent: 'flex-start',
    h: '12',
    fontWeight: 'normal',
    alignItems: 'center',
    w: 'full',
    _hover: {
      outlineColor: 'primary.red',
      outlineOffset: '-2px',
    },
    _focusVisible: {
      outlineColor: 'primary.red',
      outlineOffset: '-2px',
    },
  },
} as const satisfies SystemStyleInterpolation

/**
 * Used for E2E tests.
 */
const TAG = 'CategoryMenuButton'

const CategoryMenuButton: FunctionComponent<CategoryMenuButtonProps> = ({ category }) => {
  const display = useLocalizedString()

  return (
    <Button
      data-id={category.id}
      key={display(category.name)}
      as={NextLink}
      href={
        category.isMainRoot
          ? RoutePaths.products
          : assignParamsToUrl(RoutePaths.productsCategory, { slug: display(category.slug) })
      }
      leftIcon={
        <Image
          src={getCategoryImageByName(display(category.name))}
          width={DEFAULT_WH}
          height={DEFAULT_WH}
          alt={display(category.name)}
          mr="2"
        />
      }
      {...categoryButtonStyles.button}
    >
      {display(category.name)}
    </Button>
  )
}

CategoryMenuButton.displayName = TAG

export default CategoryMenuButton
