import type { BaseCategory } from '@commercetools/platform-sdk'

function isRootCategory(category: BaseCategory | null | undefined): boolean {
  if (category) {
    return category.ancestors.length === 0
  }
  return false
}

export default isRootCategory
