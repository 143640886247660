import type { BaseCategory, FacetResults, TermFacetResult } from '@commercetools/platform-sdk'
import { useQuery } from '@tanstack/react-query'
import omit from 'lodash/omit'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useMemo } from 'react'

import getInitialFacets from 'commercetools/products/getInitialFacets'
import useBlanketAgreements from 'utils/Providers/BlanketAgreements/useBlanketAgreements'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'
import { GlobalPropsContext } from 'utils/Providers/GlobalProps/context'

export interface FacetCategoriesProps extends BaseCategory {
  count: number
  isMainRoot?: boolean
}
interface FacetCategoriesResponse {
  categories: Array<FacetCategoriesProps> | undefined
  otherFacets: FacetResults | undefined
}

/**
 * Function making call to commercetools to get facets for all products.
 * @returns  categories and some filters for initial products
 *
 */
function useInitialFacets(): FacetCategoriesResponse {
  const { t } = useTranslation()
  const blanketAgreements = useBlanketAgreements()

  const {
    data: { key: businessUnitKey },
  } = useBusinessUnit()

  const { categories } = useContext(GlobalPropsContext)
  const response = useQuery(
    ['getInitialFacets', businessUnitKey, blanketAgreements.data.id, blanketAgreements.data.currency],
    getInitialFacets,
    {
      enabled: !!blanketAgreements.data.id,
    },
  )

  const categoriesTerms = response.data?.facets?.['categories.id'] as TermFacetResult
  const otherFacets = omit(response.data?.facets, 'categories.id')

  return useMemo(() => {
    // We want to have always button for all categories
    const allProductsCategory = {
      id: 'id-all-categories',
      version: 1,
      name: {
        en: t('components.breadcrumbs.allProducts'),
      },
      slug: {},
      ancestors: [],
      count: response.data?.total || 0,
      isMainRoot: true,
    } as FacetCategoriesProps

    const matchedCategories =
      categoriesTerms?.terms.map((term) => {
        const matchingTerm = categories.find(({ id }) => id === term.term)
        return matchingTerm && { ...matchingTerm, count: term.count }
      }) ?? []

    // Add mocked category "all products" as first element
    matchedCategories.unshift(allProductsCategory)

    return { categories: matchedCategories as Array<FacetCategoriesProps>, otherFacets }
  }, [categories, categoriesTerms?.terms, otherFacets, response.data?.total, t])
}

export default useInitialFacets
