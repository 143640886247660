import { Box, Container, Stack, SystemStyleInterpolation } from '@chakra-ui/react'
import React from 'react'

import ErrorBoundary from 'components/elements/ErrorBoundary'
import getLayoutAuthorized from 'components/layouts/Authorized'
import AdminSidebar from 'components/modules/Admin/AdminSidebar'

export interface LayoutAdminProps {}

const TAG = 'LayoutAdminProps'

const styles = {
  wrapper: {
    direction: { base: 'column', lg: 'row' },
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    py: '8',
    spacing: '6',
    px: { base: '0', md: '8' },
  },
  article: {
    maxW: 'desktop-content',
    minH: 'full',
    w: 'full',
    minW: '0',
  },
} as const satisfies SystemStyleInterpolation

const AdminLayout: LayoutComponent<LayoutAdminProps> = (props, page) =>
  getLayoutAuthorized(props)(
    <Container id={TAG} data-testid={TAG} as={Stack} {...styles.wrapper}>
      <AdminSidebar />
      <Box as="article" {...styles.article}>
        <ErrorBoundary>{page}</ErrorBoundary>
      </Box>
    </Container>,
  )

function getLayoutAdmin(props: LayoutAdminProps = {}): GetLayout {
  return (page) => AdminLayout(props, page)
}

export default getLayoutAdmin
