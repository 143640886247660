import noop from 'lodash/noop'
import { createContext } from 'react'

export interface MenuDrawerContextValue {
  currentMenu: string
  setCurrentMenu: (menu: string) => void
  backToMainMenu: () => void
  closeMenu: () => void
}

const initialValue: MenuDrawerContextValue = {
  currentMenu: '',
  setCurrentMenu: noop,
  backToMainMenu: noop,
  closeMenu: noop,
}

export const MenuDrawerContext = createContext<MenuDrawerContextValue>(initialValue)
