import React, { FunctionComponent, ReactNode } from 'react'

import type { UserRoles } from 'config/auth'
import type { Level } from 'hooks/useMyRoles'
import useMyRoles from 'hooks/useMyRoles'

interface GuardRoleProps {
  /**
   * Children to render if the user is allowed to see them.
   */
  children: ReactNode
  /**
   * Allowed roles to see the children.
   */
  allowedRoles: ReadonlyArray<UserRoles> | UserRoles
  /**
   * Fallback component to render if the user is not allowed to see the children.
   * @default null
   */
  fallback?: ReactNode
  /**
   * The level of the role to check.
   * Roles are defined on two levels:
   * - Customer: the role of the customer itself.
   * - Business Unit: the role of the customer in the business unit.
   *
   * @default 'businessUnit'
   */
  level?: Level
}

/**
 * Used for E2E tests.
 */
const TAG = 'GuardRole'

const GuardRole: FunctionComponent<GuardRoleProps> = ({
  children,
  allowedRoles,
  fallback = null,
  level = 'businessUnit',
}) => {
  const allowedRolesArray = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles]
  const userRoles = useMyRoles(level)
  const hasAccess = allowedRolesArray.some((role) => userRoles.some((userRole) => userRole === role))

  if (hasAccess) {
    return <>{children}</>
  }
  return <>{fallback}</>
}

GuardRole.displayName = TAG

export default GuardRole
