import type { SystemStyleObject, ThemeComponents } from '@chakra-ui/react'

/**
 * Override button component configuration.
 * @link https://chakra-ui.com/docs/components/button
 * @default `src\node_modules\@chakra-ui\theme\dist\components\button.js`
 */
export const Button = {
  defaultProps: {
    colorScheme: '', // default is `gray` but we want to use different per variant.
    variant: 'primary',
  },
  baseStyle: {
    fontSize: 'md',
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
    fontWeight: '700',
    borderRadius: 'none',
    px: 1.5,
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    primary: ({ colorScheme: color }) => {
      const colorScheme = color || 'blue'

      const _loading: SystemStyleObject = {
        color: `primary.${colorScheme}`,
        backgroundColor: 'primary.white',
      }
      const _disabled: SystemStyleObject = {
        bg: 'gray.500',
        color: 'primary.white',
      }
      const _hover: SystemStyleObject = {
        color: 'primary.white',
        backgroundColor: `primary.${colorScheme}`,
        textDecoration: 'none',
        _disabled,
        _loading,
      }

      return {
        backgroundColor: 'primary.white',
        color: `primary.${colorScheme}`,
        transition: 'all 0.2s',
        borderWidth: 2,
        borderColor: `primary.${colorScheme}`,
        _hover,
        _focusVisible: _hover,
        _disabled: _disabled,
        _loading,
      }
    },

    secondary: ({ colorScheme: color }) => {
      const colorScheme = color || 'green'

      const _loading: SystemStyleObject = {
        color: 'primary.white',
        backgroundColor: `primary.${colorScheme}`,
      }
      const _disabled: SystemStyleObject = {
        borderColor: 'primary.white',
        bg: 'gray.500',
        color: 'primary.white',
      }
      const _hover: SystemStyleObject = {
        color: 'primary.white',
        textDecoration: 'none',
        backgroundColor: `primary.black`,
        _disabled,
        _loading,
      }

      return {
        color: 'primary.white',
        backgroundColor: `primary.${colorScheme}`,
        _hover,
        _focusVisible: _hover,
        _disabled,
        _loading,
      }
    },

    outline: ({ colorScheme: color }) => {
      const colorScheme = color || 'red'

      const _loading: SystemStyleObject = {
        color: 'primary.white',
        borderColor: `primary.${colorScheme}`,
        backgroundColor: `primary.${colorScheme}`,
      }
      const _disabled: SystemStyleObject = {
        borderColor: 'gray.500',
        bg: 'gray.500',
        color: 'primary.white',
      }
      const _hover: SystemStyleObject = {
        color: 'primary.white',
        textDecoration: 'none',
        borderColor: `primary.${colorScheme}`,
        backgroundColor: `primary.${colorScheme}`,
        _disabled,
        _loading,
      }

      return {
        color: 'primary.white',
        transition: 'all 0.2s',
        borderWidth: 2,
        borderColor: 'gray.300',
        _hover,
        _focusVisible: _hover,
        _disabled,
        _loading,
      }
    },
    menu: {
      default: {
        size: 'md',
      },
      color: 'primary.black',
      fontWeight: 'normal',
      _hover: {
        color: 'primary.blue',
        textDecoration: 'underline',
      },
      _active: {
        color: 'primary.blue',
        textDecoration: 'underline',
      },

      _focusVisible: {
        color: 'primary.blue',
        textDecoration: 'underline',
      },
    },
    link: {
      color: 'primary.blue',
      minH: 'auto',
      border: 'none',
      fontWeight: 'normal',
      _active: {
        opacity: 0.8,
      },
      _hover: {
        opacity: 0.8,
        textDecoration: 'underline',
      },
      _focusVisible: {
        opacity: 0.8,
        textDecoration: 'underline',
      },
    },
  },

  sizes: {
    md: {
      minH: '2.5rem',
      fontSize: 'sm',
    },
    lg: {
      minH: '3rem',
      fontSize: 'sm',
      px: '3.75rem',
    },
  },
} satisfies ThemeComponents['Button']
