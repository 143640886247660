import type { SystemStyleInterpolation } from '@chakra-ui/react'

export const global: Partial<SystemStyleInterpolation> = {
  html: {
    height: '100%',
  },
  body: {
    display: 'grid',
    gridTemplateRows: '1fr',
    backgroundColor: 'primary.white',
    color: 'primary.black',
    fontSize: 'sm',
    scrollbarWidth: 'auto',
    printColorAdjust: 'exact',
  },
  '#__next': {
    minWidth: 0,
  },
  a: {
    cursor: 'pointer',
  },
  svg: {
    fill: 'currentColor',
    overflow: 'visible',
  },
  h1: {
    fontSize: 'xl',
    lineHeight: 2,
  },
  h2: {
    fontSize: 'lg',
    lineHeight: 1.375,
  },
  h3: {
    fontSize: 'md',
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 'md',
    lineHeight: 1.5,
  },
  'a[aria-current]': {
    fontWeight: 'bold',
  },

  '*': {
    scrollbarWidth: 'thin',
    scrollbarColor: '#ccc',
  },
  '*::-webkit-scrollbar': {
    width: '0.375rem',
    height: '0.375rem',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '1rem',
    transition: 'background-color 0.2s ease',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#999',
  },
  '.grecaptcha-badge ': {
    visibility: 'hidden',
  },
  '@font-face': [
    {
      fontFamily: `'DIN Pro'`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontDisplay: 'swap',
      src: [
        `local('D-DIN')`,
        `local('DINPro')`,
        `local('DINPro-Regular')`,
        `url('/fonts/DINPro.woff2') format('woff2')`,
        `url('/fonts/DINPro.woff') format('woff')`,
        `url('/fonts/DINPro.otf') format('opentype')`,
      ].join(),
    },
    {
      fontFamily: `'DIN Pro'`,
      fontStyle: 'normal',
      fontWeight: 700,
      fontDisplay: 'swap',
      src: [
        `local('D-DIN-Bold')`,
        `local('DINPro-Bold')`,
        `local('D-DIN')`,
        `local('DINPro')`,
        `url('/fonts/DINPro-Bold.woff2') format('woff2')`,
        `url('/fonts/DINPro-Bold.woff') format('woff')`,
        `url('/fonts/DINPro-Bold.otf') format('opentype')`,
      ].join(),
    },
    // Generated with `pixel-point/fontpie`
    {
      fontFamily: 'DIN Pro Fallback',
      src: 'local(Arial)',
      fontStyle: 'normal',
      fontWeight: 400,
      ascentOverride: '112.78%',
      descentOverride: '27.49%',
      lineGapOverride: '1.00%',
      sizeAdjust: '99.66%',
    },

    {
      fontFamily: 'DIN Pro Fallback',
      src: "local('Arial')",
      fontStyle: 'normal',
      fontWeight: 700,
      ascentOverride: '111.25%',
      descentOverride: '28.74%',
      lineGapOverride: '0.97%',
      sizeAdjust: '102.65%',
    },
  ],
  '.mouse-animation': {
    animationName: 'mouseCursor',
    animationDuration: '1.2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
  },

  '@keyframes mouseCursor': {
    from: {
      transform: 'translateY(4px)',
      opacity: 1,
    },
    to: {
      transform: 'translateY(18px)',
      opacity: 0,
    },
  },

  // OneTrust Cookie Settings button styles
  '#ot-sdk-btn.ot-sdk-show-settings.custom-ot-sdk-btn': {
    p: 0,
    backgroundColor: 'transparent !important',
    border: 'none !important',
    color: 'primary.blue',
    textTransform: 'none',
    fontWeight: 'inherit',
    height: 'min-content',
  },

  'button#ot-sdk-btn.ot-sdk-show-settings.custom-ot-sdk-btn:where(:hover, :focus-visible)': {
    color: 'primary.blue !important',
    backgroundColor: 'transparent !important',
    border: 'none !important',
  },
} as const
