import type { ChakraTheme } from '@chakra-ui/react'

export const fontSizes: ChakraTheme['fontSizes'] = {
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.5rem', // 24px
  '2xl': '2rem', // 32px
}
