import type { ClientResponse, Customer } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'

import { CT_MY_CLIENT } from 'commercetools/client/user'

type QueryKey = [queryFnName: string]

type Arguments = QueryFunctionContext<QueryKey>

/**
 * @returns currently logged in customer
 */
function getMe({ signal }: Arguments): Promise<ClientResponse<Customer>> {
  return CT_MY_CLIENT.me().get().execute({ signal })
}

export default getMe
