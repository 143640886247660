import { Box, HStack, Skeleton, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'TopNavigationBarLoading'

const styles = {
  stack: {
    spacing: '4',
  },
  divider: {
    h: '4',
    w: '1px',
    opacity: '0.6',
    bg: 'primary.white',
  },
  lastDivider: {
    h: '4',
    w: '1px',
    opacity: '0.6',
    bg: 'primary.white',
    display: {
      base: 'block',
      lg: 'none',
    },
  },
  shoppingList: {
    h: '4',
    width: { base: '1rem', lg: '135px' },
  },
  profile: {
    h: '4',
    width: { base: '1rem', lg: '190px' },
  },
  cart: {
    h: '4',
    width: '1rem',
    marginRight: '.75rem !important',
    display: { base: 'block', lg: 'none' },
  },
} as const satisfies SystemStyleInterpolation

const TopNavigationBarLoading: FunctionComponent = () => (
  <HStack {...styles.stack}>
    <Skeleton {...styles.shoppingList} />
    <Box {...styles.divider} />
    <Skeleton {...styles.profile} />
    <Box {...styles.lastDivider} />
    <Skeleton {...styles.cart} />
  </HStack>
)

TopNavigationBarLoading.displayName = TAG

export default TopNavigationBarLoading
