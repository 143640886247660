import { Button, Icon, Skeleton, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'

import MenuIcon from 'assets/menu.svg?component'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import CategoryMenuContent from 'components/modules/Header/MobileMenu/CategoryMenuContent'
import MainMenuContent from 'components/modules/Header/MobileMenu/MainMenuContent'
import MyAccountMenuContent from 'components/modules/Header/MobileMenu/MyAccountMenuContent'
import { MenuDrawerContext } from 'components/modules/Header/MobileMenu/Provider/context'

/**
 * Used for E2E tests.
 */
const TAG = 'MobileMenuMainButton'

const styles = {
  loading: {
    display: { base: 'block', lg: 'none' },
    h: '10',
    w: '24',
  },
  button: {
    display: { base: 'flex', lg: 'none' },
  },
  icon: {
    boxSize: '1.25rem',
    color: 'inherit',
  },
} as const satisfies SystemStyleInterpolation

const MobileMenuMainButton: FunctionComponent = () => {
  const { t } = useTranslation()
  const { backToMainMenu: openMenu } = useContext(MenuDrawerContext)

  const { status } = useAuthState()

  if (status === AuthStatus.Loading) {
    return <Skeleton {...styles.loading} />
  }

  return (
    <>
      <Button
        data-testid={TAG}
        onClick={openMenu}
        aria-label={t('components.header.menu.label')}
        leftIcon={<Icon aria-hidden="true" as={MenuIcon} {...styles.icon} />}
        {...styles.button}
      >
        {t('components.header.menu.button')}
      </Button>
      <MainMenuContent />
      <MyAccountMenuContent />
      <CategoryMenuContent />
    </>
  )
}

MobileMenuMainButton.displayName = TAG

export default MobileMenuMainButton
