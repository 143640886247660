import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { FunctionComponent, ReactNode } from 'react'

import updateMyAccount from 'commercetools/customer/updateMyAccount'
import AuthInit from 'components/modules/Auth/AuthInit'
import { msalConfig } from 'config/auth'
import CustomNavigationClient from 'utils/Providers/Auth/NavigationClient'
import BlanketAgreementsProvider from 'utils/Providers/BlanketAgreements'
import BusinessUnitProvider from 'utils/Providers/BusinessUnit'
import CartProvider from 'utils/Providers/Cart'
import { queryClient } from 'utils/Providers/QueryClient'

interface AuthProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'Auth'

/**
 * MSAL instance.
 * Please use `useMsal` hook instead of this instance.
 * @example
 * ```tsx
 * const { instance } = useMsal()
 * ```
 */
export const msalInstance = new PublicClientApplication(msalConfig)

/**
 * Thanks to that and below `addEventCallback`, we can use `instance.getActiveAccount()`
 * @example #1
 * ```ts
 * const account = msalInstance.getActiveAccount()
 * ```
 *
 * @example #2
 * ```tsx
 * const { instance } = useMsal()
 * const account = instance.getActiveAccount()
 * ```
 */

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    event.payload &&
    'account' in event.payload &&
    event.payload.account
  ) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)

    updateMyAccount({
      action: 'setCustomField',
      name: 'lastLoginDate',
      value: new Date().toISOString(),
    }).then(() => queryClient.invalidateQueries(['getMe']))
  }
})

/**
 * MSAL should understand that we are using Next.js and Next.js routing system.
 * More information in @see {@link CustomNavigationClient}
 */
const navigationClient = new CustomNavigationClient()
msalInstance.setNavigationClient(navigationClient)

/**
 * Auth Provider.
 *
 * That provider is responsible for initializing the MSAL (Azure Active Directory B2C) with Commercetools.
 *
 * When it comes to the Commercetools, Authentication is far more complicated. We are using the MSAL library
 * to get currently user access token. AD B2C and Commercetools are connected using introspect endpoint.
 * @link https://docs.commercetools.com/api/authorization#introspection
 * So that we can use the access token to get the user data from Commercetools.
 *
 * @example
 * ```tsx
 * // This is pseudo code. It's not a real code.
 * // It's just to show how we can use the Auth context.
 * const { instance } = useMsal()
 * useEffect(() => {
 *   const account = instance.getActiveAccount()
 *   if (account) {
 *     const accessToken = await instance.acquireTokenSilent({ ..., account }).then((response) => response.accessToken)
 *     // We are using the access token to get products data from Commercetools.
 *     // Commercetools internally asks the introspect endpoint to check if the access token is valid.
 *     // Introspect endpoint is a Azure function which is connected to AD B2C.
 *     // If the access token is valid, Commercetools returns the products data.
 *     const client = new CommerceToolsClient({ accessToken }) // See `src\commercetools\client\user.ts`
 *     const products = await client.products().get().execute()
 *   }
 * }, [instance])
 * ```
 *
 * Beside children, the component renders also the AuthInit component.
 * AuthInit is responsible for initializing the Auth context and storing the user in the context.
 *
 * @see components\modules\Auth\AuthInit
 * @see https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
 */
const Auth: FunctionComponent<AuthProps> = ({ children }) => (
  <MsalProvider instance={msalInstance}>
    <AuthInit>
      <BusinessUnitProvider>
        <BlanketAgreementsProvider>
          <CartProvider>{children}</CartProvider>
        </BlanketAgreementsProvider>
      </BusinessUnitProvider>
    </AuthInit>
  </MsalProvider>
)

Auth.displayName = TAG

export default Auth
