import { useContext } from 'react'

import { Authenticated, AuthInitContext, AuthInitContextValue } from 'components/modules/Auth/AuthInit/context'

/**
 * Returns Auth context.
 *
 * @example
 * ```tsx
 * const { status, user, customer } = useAuthState()
 * // user: AccountInfo | null
 * // customer: Customer | null
 * ```
 *
 * When you are within @see GuardAuthorized component,
 * you can use `useAuthState(true)` to get more accurate types
 *
 * @example
 * ```tsx
 * const { status, user, customer } = useAuthState(true)
 * // user: AccountInfo
 * // customer: Customer
 * ```
 *
 * @param isAuthorized - if true, will return Authenticated context
 */
function useAuthState(isAuthorized: true): Authenticated
function useAuthState(isAuthorized?: boolean): AuthInitContextValue
function useAuthState(): AuthInitContextValue {
  return useContext(AuthInitContext)
}

export default useAuthState
