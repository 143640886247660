import { categoryImageMapping } from 'config/categories'
import { DEFAULT_PRODUCT_IMAGE } from 'config/product'

const getCategoryImageByName = (categoryName: string) => {
  // Check if the image exists in the mapping
  if (categoryImageMapping.hasOwnProperty(categoryName)) {
    return categoryImageMapping[categoryName]
  }
  // Return the default image if the image does not exist
  return DEFAULT_PRODUCT_IMAGE
}

export default getCategoryImageByName
