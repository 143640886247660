import { Stack, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import BottomRowMenu from 'components/modules/Footer/BottomRowMenu'
import Copyright from 'components/modules/Footer/Copyright'

const TAG = 'BottomRow'

const styles = {
  wrapper: {
    gridArea: 'bottom',
    w: 'full',
    paddingBlock: '6',
    borderBlockStart: '1px solid',
    borderColor: 'gray.200',
    direction: { base: 'column', md: 'row' },
    alignItems: { base: 'flex-start', md: 'center' },
    justifyContent: 'space-between',
    spacing: '6',
  },
} as const satisfies SystemStyleInterpolation

const BottomRow: FunctionComponent = () => (
  <Stack data-testid={TAG} {...styles.wrapper}>
    <Copyright />
    <BottomRowMenu />
  </Stack>
)

BottomRow.displayName = TAG

export default BottomRow
