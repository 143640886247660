import { Box, List, ListItem, Stack, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Link from 'components/elements/Link'
import { FOOTER_MENU_SOCIALS } from 'components/modules/Footer/config'

const TAG = 'MenuSocials'

const styles = {
  nav: {
    gridArea: 'socials',
  },
  stack: {
    direction: 'row',
    spacing: { base: '6', md: '4' },
    justifyContent: { base: 'flex-start', md: 'flex-end' },
  },
  link: {
    width: '10',
    height: '10',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: 'primary.red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      backgroundColor: 'primary.black',
    },
    _focusVisible: {
      backgroundColor: 'primary.black',
      boxShadow: 'outline',
    },
  },
} as const satisfies SystemStyleInterpolation

const MenuSocials: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Box as="nav" data-testid={TAG} aria-label={t('components.footer.socials.label')} {...styles.nav}>
      <Stack as={List} {...styles.stack}>
        {FOOTER_MENU_SOCIALS.map(({ href, translateKey, ...props }) => (
          <ListItem key={translateKey}>
            <Link href={href} {...styles.link} {...props} rel="noopener noreferrer" aria-label={t(translateKey)} />
          </ListItem>
        ))}
      </Stack>
    </Box>
  )
}

MenuSocials.displayName = TAG

export default MenuSocials
