import { useContext } from 'react'

import { CartContext, CartContextValue } from 'utils/Providers/Cart/context'

/**
 * Returns the cart context value
 *
 * Note:
 * 1. When you add or remove something from the cart, the cart context value should be updated also. Please
 * invoke `refetch` function to update the cart context value.
 */
function useCart(): CartContextValue {
  return useContext(CartContext)
}

export default useCart
