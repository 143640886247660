/**
 * Custom form input component
 * @see `src\components\elements\FormInput\index.tsx`
 *
 * @link https://chakra-ui.com/docs/components/form-control
 */
export const FormLabel = {
  baseStyle: {
    fontWeight: 'bold',
    fontSize: 'sm',
    lineHeight: 1.71,
  },
} as const
