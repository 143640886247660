import { Button, Flex, Icon, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import CartIcon from 'assets/cart.svg?component'
import CartBadge from 'components/elements/CartBadge'
import { RoutePaths } from 'config/routes'

/**
 * Used for E2E tests.
 */
const TAG = 'MenuItemCheckout'

const styles = {
  button: {
    variant: 'unstyled',
    display: 'flex',
    justifyContent: 'space-between',
    px: '4',
    fontSize: 'xl',
    fontWeight: 'normal',
    w: 'full',
  },
} as const satisfies SystemStyleInterpolation

const MenuItemCheckout: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Button
      data-testid={`${TAG}-menu-button-cart`}
      as={NextLink}
      href={RoutePaths.cart}
      aria-label={t('components.header.goToCart')}
      {...styles.button}
    >
      <Text as="span">{t('components.header.goToCheckout')}</Text>
      <Flex>
        <Icon aria-hidden="true" as={CartIcon} mr="2" boxSize="6" />
        <CartBadge />
      </Flex>
    </Button>
  )
}

MenuItemCheckout.displayName = TAG

export default MenuItemCheckout
