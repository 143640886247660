import { SystemStyleInterpolation, Box, Divider, Heading } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import MenuItemAccount from 'components/modules/Header/MobileMenu/MainMenuContent/MenuItemAccount'
import MenuItemCheckout from 'components/modules/Header/MobileMenu/MainMenuContent/MenuItemCheckout'
import MenuListButton from 'components/modules/Header/MobileMenu/MainMenuContent/MenuListButton'
import MobileMenuDrawer from 'components/modules/Header/MobileMenu/MobileMenuDrawer'
import { MainMenu, MenuDrawers } from 'config/menu'

/**
 * Used for E2E tests.
 */
const TAG = 'MainMenuContent'

const styles = {
  box: {
    px: '4',
    py: '4',
    bg: 'primary.white',
  },
} as const satisfies SystemStyleInterpolation

const MainMenuContent: FunctionComponent = () => {
  const { t } = useTranslation()
  const { customer } = useAuthState(true)

  const welcome = customer?.firstName
    ? t('components.topbar.welcome', { name: customer.firstName })
    : t('components.topbar.welcomeNoName')

  return (
    <MobileMenuDrawer name={MenuDrawers.mainMenu} bg="primary.red" header={<Heading as="h2">{welcome}</Heading>}>
      <Box {...styles.box}>
        {MainMenu.map((link) => (
          <MenuListButton key={link.translateKey} {...link} />
        ))}
      </Box>
      <Divider />
      <Box {...styles.box}>
        <MenuItemCheckout />
      </Box>
      <Box px="4" py="4" color="white">
        <MenuItemAccount />
      </Box>
    </MobileMenuDrawer>
  )
}

MainMenuContent.displayName = TAG
export default MainMenuContent
