import type {
  ClientResponse,
  ProductProjectionPagedQueryResponse,
  ProductProjectionPagedSearchResponse,
} from '@commercetools/platform-sdk'

import { EMPTY_CLIENT_RESPONSE_PAGED } from 'commercetools/const'
import getInitialFilterQuery from 'commercetools/utils/getInitialFilterQuery'
import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

/**
 * @returns a list of products
 * @link https://docs.commercetools.com/api/projects/products#query-products
 */
function getProductsByIds(
  businessUnitKey: string,
  productIds: string[] | null | unknown,
  blanketAgreementId: string,
  priceCurrency: string,
): Promise<ClientResponse<ProductProjectionPagedQueryResponse>> {
  const ids: string[] = Array.isArray(productIds) ? productIds : []

  if (ids.length === 0) {
    return Promise.resolve(EMPTY_CLIENT_RESPONSE_PAGED)
  }

  const stringifiedParams = stringifyObjectValues({
    expand: 'categories[*]',
    'filter.query': getInitialFilterQuery(blanketAgreementId),
    where: `id in ("${ids.join('","')}")`,
    limit: ids.length,
    priceCustomerGroup: blanketAgreementId,
    priceCurrency: priceCurrency,
    businessUnitKey,
  })

  const params = new URLSearchParams(stringifiedParams).toString()

  const url = `${RoutePaths.productProjectionsApi}?${params}`
  return apiAuthFetch<ClientResponse<ProductProjectionPagedSearchResponse>>(url)
}

export default getProductsByIds
