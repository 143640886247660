import { useMsal } from '@azure/msal-react'
import { Button, ButtonProps } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useCallback } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'

/**
 * Used for E2E tests.
 */
const TAG = 'LogoutButton'

const LogoutButton: FunctionComponent<ButtonProps> = (props) => {
  const { t, lang } = useTranslation()
  const { push, asPath } = useRouter()

  const { status } = useAuthState()
  const { instance } = useMsal()

  const onClick = useCallback<VoidFunction>(async () => {
    const redirectURI = new URL(process.env.NEXT_PUBLIC_SITE_URL + `/${lang}${RoutePaths.login}`)
    redirectURI.searchParams.set('redirect', asPath)

    // signOut should automatically redirect to `callbackUrl`.
    await instance.logoutRedirect({ postLogoutRedirectUri: redirectURI.href })
    // But when it doesn't, we do it manually.
    await push({ pathname: RoutePaths.login, query: { redirect: asPath } })
  }, [asPath, instance, lang, push])

  if (status === AuthStatus.Authenticated) {
    return (
      <Button data-testid={TAG} onClick={onClick} {...props}>
        {t('components.header.logout')}
      </Button>
    )
  }

  return null
}

LogoutButton.displayName = TAG

export default LogoutButton
