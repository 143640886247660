import { createContext } from 'react'

export interface GlobalPropsContextValue extends GlobalProps {}

const initialValue: GlobalPropsContextValue = {
  categories: [],
  BUILD_DATE: '',
  RESPONSE_DATE: '',
}

export const GlobalPropsContext = createContext<GlobalPropsContextValue>(initialValue)
