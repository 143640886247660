import type { ThemeComponents } from '@chakra-ui/react'

export const Heading: ThemeComponents['Heading'] = {
  sizes: {
    '2xl': { fontSize: '2xl', lineHeight: 1.3 },
    xl: { fontSize: 'xl', lineHeight: 1.5 },
    lg: { fontSize: 'lg', lineHeight: 1.35 },
    md: { fontSize: 'md', lineHeight: 1.5 },
    sm: { fontSize: 'sm', lineHeight: 1.5 },
    xs: { fontSize: 'xs', lineHeight: 1.57 },
  },
}
