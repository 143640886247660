import { isCommercetoolsOneTryError } from 'commercetools/utils/isCommercetoolsError'
import isUserNotLoggedInError from 'components/modules/Auth/utils/isUserNotLoggedInError'
import isCodeError from 'utils/isCodeError'

export const DEFAULT_RETRY_COUNT = 3

const ONE_RETRY_ERRORS_CODE = ['Error']

function shouldRetry(failureCount: number, error: unknown): boolean {
  if (
    isUserNotLoggedInError(error) ||
    isCommercetoolsOneTryError(error) ||
    (isCodeError(error) && ONE_RETRY_ERRORS_CODE.includes(error.name))
  ) {
    return false
  }

  return failureCount < DEFAULT_RETRY_COUNT
}

export default shouldRetry
