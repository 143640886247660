import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Custom form input component
 * @see `src\components\elements\FormInput\index.tsx`
 *
 * @link https://chakra-ui.com/docs/components/form-control
 * @default `src\node_modules\@chakra-ui\theme\dist\components\input.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-control.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-label.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-error.js`
 */
export const FormInput = {
  variants: {
    simple: {
      control: {},
      label: {
        fontWeight: 'bold',
        fontSize: 'sm',
        lineHeight: 1.71,
      },
      input: {
        display: 'flex',
        borderColor: 'gray.100',
        borderRadius: 0,
        minHeight: '12',
        backgroundColor: 'primary.white',
      },
      error: {},
      rightElement: {
        border: 'none',
        height: 'calc(100% - 2px)',
        top: '1px',
        right: '1px',
        transitionProperty: 'opacity, visibility',
        transitionDuration: '0.2s',
        sx: {
          '&:empty': {
            display: 'none',
          },
        },
      },
      leftElement: {
        border: 'none',
        height: 'calc(100% - 2px)',
        top: '1px',
        left: '1px',
        sx: {
          '&:empty': {
            display: 'none',
          },
        },
      },
    },
  },
} as const satisfies ThemeComponents['FormInput']
