import { Box, Container, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import ChangeCompany from 'components/modules/Header/ChangeCompany'
import UserNavigation from 'components/modules/Header/TopNavigationBar/UserNavigation'

/**
 * Used for E2E tests.
 */
const TAG = 'TopNavigationBar'

const styles = {
  nav: {
    bg: 'primary.red',
    h: { base: '14', lg: '10' },
    py: '0.375rem',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'primary.white',
    h: 'full',
  },
} as const satisfies SystemStyleInterpolation

/**
 * Extracted to component to prevent unnecessary re-renders.
 * @see https://kentcdodds.com/blog/optimize-react-re-renders
 */
const TopNavigationBarWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Box aria-label={t('components.topbar.label')} data-testid={TAG} as="nav" {...styles.nav}>
      {children}
    </Box>
  )
}

const TopNavigationBar: FunctionComponent = () => (
  <TopNavigationBarWrapper>
    <Container {...styles.wrapper}>
      <ChangeCompany />
      <UserNavigation />
    </Container>
  </TopNavigationBarWrapper>
)

TopNavigationBar.displayName = TAG

export default TopNavigationBar
