type AnyObject = Record<string, string | string[] | number | boolean>

function stringifyObjectValues(obj: AnyObject): Record<string, string> {
  const result: { [key: string]: string } = {}

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      result[key] = JSON.stringify(value)
    } else if (typeof value !== 'string') {
      result[key] = String(value)
    } else {
      result[key] = value
    }
  }

  return result
}

export default stringifyObjectValues
