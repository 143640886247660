import { Button } from '@chakra-ui/react'
import React, { ComponentProps, forwardRef, ReactNode } from 'react'

type InheritedProps = RequiredFields<ComponentProps<typeof Button>, 'onClick'>

interface ButtonModalProps extends InheritedProps {
  testid: string
  isOpen: boolean
  children?: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'ButtonModal'

/**
 * Button for Modal with all required aria attributes etc.
 *
 * @example
 * ```tsx
 * const btnRef = useRef<HTMLButtonElement>(null);
 * const { isOpen, onOpen, onClose } = useDisclosure();
 *
 * return (
 *   <>
 *     <ButtonModal ref={btnRef} isOpen={isOpen} onClick={onOpen} testid={TAG}>
 *       Open my modal
 *     </ButtonModal>
 *
 *     <Modal finalFocusRef={btnRef} id={TAG} isOpen={isOpen} onClose={onClose} data-testid={`${TAG}-modal`}>
 *       ...
 *     </Modal>
 *   </>
 * );
 * ```
 */
const ButtonModal = forwardRef<HTMLButtonElement, ButtonModalProps>(
  ({ onClick, isOpen, children, testid = TAG, ...props }, ref) => (
    <Button
      ref={ref}
      variant="link"
      onClick={onClick}
      data-testid={`${testid}-button`}
      aria-haspopup="dialog"
      aria-expanded={isOpen}
      aria-controls={`chakra-modal-${testid}`}
      {...props}
    >
      {children}
    </Button>
  ),
)

ButtonModal.displayName = TAG

export default ButtonModal
