import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  list: {
    py: 0,
    borderRadius: 'none',
  },
  item: {
    _hover: {
      backgroundColor: 'gray.100',
    },
    _focus: {
      backgroundColor: 'gray.100',
    },
  },
})
// export the base styles in the component theme
export const Menu = defineMultiStyleConfig({ baseStyle })
