import { Box, Button, Container, Icon } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, memo } from 'react'

import ChevronIcon from 'assets/chevron_right.svg?component'
import Logo from 'components/modules/Header/Logo'
import { styles } from 'components/modules/Header/style'

const HeaderUnauthenticated: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Box as="header" {...styles.headerUnauthenticated}>
      <Container {...styles.headerContainer} {...styles.headerContainerUnauthenticated}>
        <Logo />
        <Button
          as={NextLink}
          color="primary.blue"
          fontWeight="bold"
          href="https://www.kanthal.com"
          target="_blank"
          rel="noreferrer"
          border="none"
          rightIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize={2} />}
          display="inline-flex"
        >
          {t('components.header.goToKathnal')}
        </Button>
      </Container>
    </Box>
  )
}

export default memo(HeaderUnauthenticated)
