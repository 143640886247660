import { Box, Container, Grid, SystemStyleInterpolation } from '@chakra-ui/react'
import { FunctionComponent, memo } from 'react'

import BottomRow from 'components/modules/Footer/BottomRow'
import Info from 'components/modules/Footer/Info'
import MenuAbout from 'components/modules/Footer/MenuAbout'
import MenuSocials from 'components/modules/Footer/MenuSocials'

const TAG = 'Footer'

const styles = {
  footer: {
    bgColor: 'white',
    w: 'full',
    borderBlockStart: '1px solid',
    borderColor: 'gray.200',
  },
  wrapper: {
    maxW: 'desktop',
    px: { base: '6', md: '8' },
    pt: '10',
    pb: '0',
    wrap: 'wrap',
    gap: '6',
    gridTemplateColumns: {
      lg: 'auto auto 1fr auto auto',
    },
    gridTemplateAreas: {
      base: `"info"
             "categories"
             "about"
             "socials"
             "bottom"`,
      md: `"info categories about"
           "space socials socials"
           "bottom bottom bottom"`,
      lg: `"info info space categories about"
           "info info space socials socials"
           "bottom bottom bottom bottom bottom"`,
    },
    sx: {
      '& > *': {
        minW: 0,
      },
    },
  },
} as const satisfies SystemStyleInterpolation

const Footer: FunctionComponent = () => (
  <Box as="footer" data-testid={TAG} {...styles.footer}>
    <Container as={Grid} {...styles.wrapper}>
      <Info />
      <MenuAbout />
      <MenuSocials />
      <BottomRow />
    </Container>
  </Box>
)

Footer.displayName = TAG

export default memo(Footer)
