import type { Colors } from '@chakra-ui/react'

export const colors: Colors = {
  primary: {
    black: '#000000',
    red: '#E2221B',
    white: '#FFFFFF',
    green: '#10854E',
    willowGreen: '#05D273',
    robinBlue: '#d1e5ed',
    blue: '#0C5A7A',
    blueBg: '#0C5A7A10',
    lightBlue: '#1D72DB',
    sea: '#0B7999',
    iceBlue: '#F3F8FA',
    orange: '#FF6900',
    pink: '#FED7D7',
    yellow: '#FFC700',
  },
  gray: {
    50: '#FAFAFA',
    100: '#EBEBEB',
    200: '#D8D8D8',
    300: '#D4D4D4',
    400: '#A0A0A0',
    500: '#717171',
    600: '#CCCCCC',
  },
}
