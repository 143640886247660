import { Box, Container, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ButtonBack from 'components/elements/ButtonBack'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'

const TAG = 'BackToTheStore'

const styles = {
  backContainer: {
    paddingBlockStart: 4,
    paddingBlockEnd: 6,
  },
} as const satisfies SystemStyleInterpolation

const BackToTheStore: FunctionComponent = () => {
  const { t } = useTranslation('cartCheckout')

  const { status } = useAuthState()
  const isNotAuthenticated = status === AuthStatus.Unauthenticated
  if (isNotAuthenticated) {
    return null
  }

  return (
    <Box data-testid={TAG}>
      <Container {...styles.backContainer}>
        <ButtonBack url={RoutePaths.home}>{t('components.header.backToTheStore')}</ButtonBack>
      </Container>
    </Box>
  )
}

BackToTheStore.displayName = TAG

export default BackToTheStore
