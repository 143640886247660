import type { SystemStyleInterpolation } from '@chakra-ui/react'

export const styles = {
  headerUnauthenticated: {
    w: 'full',
    minH: { base: '48px', md: '88px' },
    backgroundColor: 'primary.white',
  },
  header: {
    w: 'full',
    minH: { base: '49px', lg: '110px' } /* + 1px border */,
    backgroundColor: 'primary.white',
    boxShadow: { base: 'md', lg: 'none' },
  },
  headerContainer: {
    display: 'grid',
    gridTemplateColumns: {
      base: '1fr auto',
      lg: 'auto 1fr auto',
    },
    gridTemplateRows: {
      base: '1.8rem ',
      xl: '3rem',
    },

    px: { base: '4', md: '8' },
    paddingBlockStart: { base: '4', lg: '8' },
    paddingBlockEnd: { base: '6', lg: '5' },
  },
  headerContainerUnauthenticated: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gap: '1rem',
    paddingBlockStart: { base: '2', md: '6' },
    paddingBlockEnd: { base: '2', md: '6' },
  },
} as const satisfies SystemStyleInterpolation
