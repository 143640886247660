import { useMemo } from 'react'

import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface BusinessUnitCapabilities {
  canPutOrder: boolean
}

/**
 * Return capabilities from business unit
 * @returns BusinessUnitCapabilities
 */
function useBusinessUnitCapabilitites(): BusinessUnitCapabilities {
  const businessUnit = useBusinessUnit()

  const blanketAgreementIds = useMemo(
    () =>
      businessUnit?.data?.current?.custom?.fields?.erpBlanketAgreements &&
      Array.isArray(businessUnit?.data?.current?.custom?.fields?.erpBlanketAgreements),
    [businessUnit],
  )

  return {
    canPutOrder: blanketAgreementIds !== undefined,
  }
}

export default useBusinessUnitCapabilitites
