import { authFetch } from 'commercetools/client/user'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'

/**
 * Fetches data from the API using the given `input` and `init` parameters.
 * Parses the response as JSON and returns the result.
 */
function apiAuthFetch<DATA>(input: RequestInfo | URL, init?: RequestInit): Promise<DATA> {
  return authFetch(input, init)
    .then((response) => {
      if (response.ok) {
        return jsonOrEmptyTextMapper<DATA>(response)
      }
      throw response
    })
    .catch((error) =>
      jsonOrEmptyTextMapper(error).then((response) => {
        throw response
      }),
    )
}

export default apiAuthFetch
