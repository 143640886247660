/**
 * Util to sort texts alphabetically.
 *
 * .localeCompare is much slower than this.
 * @link https://stackoverflow.com/a/52369951
 */
function sortText(textA: string, textB: string) {
  const lowerTextA = textA.toLowerCase()
  const lowerTextB = textB.toLowerCase()
  return lowerTextA < lowerTextB ? -1 : lowerTextA > lowerTextB ? 1 : 0
}

export default sortText
