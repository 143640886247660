import { Button, Skeleton } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import NavigationCategories from 'components/modules/Header/NavigationBar/NavigationCategories'
import { RoutePaths } from 'config/routes'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'

/**
 * Used for E2E tests.
 */
const TAG = 'Navigation'

const Navigation: FunctionComponent = () => {
  const { t } = useTranslation()
  const { status } = useAuthState()
  const businessUnitCapabilitites = useBusinessUnitCapabilitites()

  if (status === AuthStatus.Loading) {
    return <Skeleton h="4" w="160px" maxW="full" />
  }

  return (
    <>
      {businessUnitCapabilitites.canPutOrder && (
        <>
          <NavigationCategories />
          <Button pr="8" as={NextLink} variant="menu" href={RoutePaths.blanketAgreements}>
            {t('components.header.blanketAgreements')}
          </Button>
        </>
      )}
      <Button pl="0" as={NextLink} variant="menu" href={RoutePaths.orders}>
        {t('components.header.myOrders')}
      </Button>
    </>
  )
}

Navigation.displayName = TAG

export default Navigation
