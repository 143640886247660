export const categoryImageMapping = {
  Wire: '/images/categories/wire.jpg',
  erpTechspecWireType01: '/images/categories/wire-type-stranded-wire.jpg',
  erpTechspecWireType03: '/images/categories/wire-type-wire-rod.jpg',
  Strip: '/images/categories/strip.jpg',
  'Billets and Ingots': '/images/categories/billets.jpg',
  Ribbon: '/images/categories/ribbons.jpg',
  Stranded: '/images/categories/wire-type-stranded-wire.jpg',
  'All products': '/images/categories/all.jpg',
  Thermocouple: '/images/categories/thermocouple.jpg',
} as CategoryImageMapping

export type CategoryImageMapping = Record<string, string>
