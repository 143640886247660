import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import setCustomerBusinessUnit from 'commercetools/businessUnits/setCustomerBusinessUnit'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { BUSINESS_UNIT_STORAGE_KEY } from 'config/businessUnit'
import useStateCache from 'hooks/useStateCache'

/**
 * @returns Customer selected business unit key.
 * @private for BusinessUnitProvider
 */
function useMyBusinessUnitKey(defaultBUKey: string | undefined): string {
  /**
   * We need to use the business unit key from storage when the customer query is still loading.
   * But when the customer query is already loaded, we should use the business unit key from the query data,
   * because this is the most up-to-date and valid user business unit key.
   * When `customerBU` is the same as `businessUnitKeySaved`, it means that we gained on that simple optimization,
   * because queries that relays on that key will not be called again because `queryKey` didn't change.
   */
  const [businessUnitKeySaved, setBusinessUnitKey] = useStateCache(BUSINESS_UNIT_STORAGE_KEY, '')
  const { status, customer } = useAuthState()

  const customerBU = status === AuthStatus.Authenticated ? customer.custom?.fields.selectedBusinessUnit : ''
  const businessUnitKey: string = customerBU || businessUnitKeySaved || defaultBUKey || ''

  /**
   * We want to update the business unit key in storage when the user is authenticated
   * in order to keep the business unit key in sync with the user data.
   */
  useEffect(() => {
    if (status === AuthStatus.Authenticated) {
      setBusinessUnitKey(businessUnitKey)
    }
  }, [businessUnitKey, setBusinessUnitKey, status])

  /**
   * We want to update the business unit key in Commercetools when the user is authenticated
   * and value in customer custom field is empy.
   */
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: setCustomerBusinessUnit,
    onSuccess: () => queryClient.invalidateQueries(['getMe']),
  })

  const customerVersion = customer?.version
  useEffect(() => {
    if (status === AuthStatus.Authenticated && customerVersion && !customerBU) {
      mutate({ businessUnitKey, customerVersion })
    }
  }, [businessUnitKey, customerBU, customerVersion, mutate, status])

  return businessUnitKey
}

export default useMyBusinessUnitKey
