import type { Cart } from '@commercetools/platform-sdk'
import type { UseQueryResult } from '@tanstack/react-query'
import { createContext } from 'react'

interface CartContextValueBase {
  refetch: UseQueryResult<Cart>['refetch']
}

interface CartContextValueLoading extends CartContextValueBase {
  isSuccess: false
  isLoading: true
  isError: false
  data: Cart | undefined
}

interface CartContextValueSuccess extends CartContextValueBase {
  isSuccess: true
  isLoading: false
  isError: false
  data: Cart
}

interface CartContextValueError extends CartContextValueBase {
  isSuccess: false
  isLoading: false
  isError: true
  data: undefined
}

export type CartContextValue = CartContextValueLoading | CartContextValueSuccess | CartContextValueError

const initialValue: CartContextValue = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  data: undefined,
  refetch: Promise.resolve,
}

export const CartContext = createContext<CartContextValue>(initialValue)
