import { Box, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import MenuCollapse from 'components/elements/MenuCollapse'
import type { MenuLink } from 'components/elements/MenuList'
import { FOOTER_MENU_ABOUT } from 'components/modules/Footer/config'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const DEFAULT_CONTACT_EMAIL = 'eshop@kanthal.com'

const TAG = 'MenuAbout'

const styles = {
  nav: {
    gridArea: 'about',
    minW: { lg: '10rem' },
    textTransform: 'uppercase',
  },
} as const satisfies SystemStyleInterpolation

const MenuAbout: FunctionComponent = () => {
  const { t } = useTranslation()

  const businessUnit = useBusinessUnit()
  const { contactEmail } = businessUnit.data

  const contactUsMenuItem: MenuLink[] = [
    {
      href: contactEmail ? `mailto:${contactEmail}` : `mailto:${DEFAULT_CONTACT_EMAIL}`,
      target: '_blank',
      rel: 'noopener noreferrer',
      translateKey: 'components.footer.about.contactUs',
    },
  ]

  const menu: MenuLink[] = [...contactUsMenuItem, ...FOOTER_MENU_ABOUT]

  return (
    <Box as="nav" data-testid={TAG} aria-label={t('components.footer.about.label')} {...styles.nav}>
      <MenuCollapse menu={menu} title={t('components.footer.about.label')} />
    </Box>
  )
}

MenuAbout.displayName = TAG

export default MenuAbout
