import { Box, BoxProps, Divider, Heading, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import getCustomerFullName from 'commercetools/utils/getCustomerFullName'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import LogoutButton from 'components/modules/Header/LogoutButton'
import SidebarLink from 'components/modules/Sidebar/SidebarLink'
import { useAccountMenu } from 'config/menu'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

/**
 * Used for E2E tests.
 */
const TAG = 'Sidebar'

const styles = {
  aside: {
    display: { base: 'none', lg: 'flex' },
    w: 'full',
    flexDirection: 'column',
    minW: { base: '100%', md: '33%', lg: 'side-block' },
    maxW: { base: '100%', md: '33%', lg: 'side-block' },
    border: '1px solid',
    borderColor: 'gray.100',
    p: '6',
    position: { base: 'static', lg: 'sticky' },
    insetBlockStart: '2rem',
  },
} as const satisfies SystemStyleInterpolation

const Sidebar: FunctionComponent<BoxProps> = (props) => {
  const { customer } = useAuthState(true)
  const { t } = useTranslation()

  const businessUnit = useBusinessUnit()
  const companyName = businessUnit.data.current?.name || customer?.companyName

  const accountMenu = useAccountMenu()

  return (
    <Box
      id={TAG}
      data-testid={TAG}
      as="aside"
      aria-label={t('components.sidebar.ariaLabel')}
      {...styles.aside}
      {...props}
    >
      <Box mb="4">
        <Heading size="md">{getCustomerFullName(customer)}</Heading>
        <Text color="gray.500" mb="4">
          {companyName}
        </Text>
        <Divider mb="4" />
      </Box>

      <Stack as="nav" aria-label={t('components.sidebar.nav')} direction="column" spacing="4" {...props}>
        {accountMenu.map((link, index) => (
          <SidebarLink key={`${index}-${link.translateKey}`} {...link} />
        ))}

        <LogoutButton variant="link" justifyContent="flex-start" />
      </Stack>
    </Box>
  )
}

export default Sidebar
