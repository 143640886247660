import type { CustomerGroup } from '@commercetools/platform-sdk'
import type { UseQueryResult } from '@tanstack/react-query'
import { createContext } from 'react'

interface BlanketAgreementsContextValueBase {
  refetch: UseQueryResult<CustomerGroup>['refetch']
}

interface BlanketAgreementsContextValueLoading extends BlanketAgreementsContextValueBase {
  isSuccess: false
  isLoading: true
  isError: false
  data: {
    id: string
    current: CustomerGroup | undefined
    all: CustomerGroup[]
    currency: string
  }
}

interface BlanketAgreementsContextValueSuccess extends BlanketAgreementsContextValueBase {
  isSuccess: true
  isLoading: false
  isError: false
  data: {
    id: string
    current: CustomerGroup | undefined
    all: CustomerGroup[]
    currency: string
  }
}

interface BlanketAgreementsContextValueError extends BlanketAgreementsContextValueBase {
  isSuccess: false
  isLoading: false
  isError: true
  data: {
    id: string
    current: undefined
    all: []
    currency: string
  }
}

export type BlanketAgreementsContextValue =
  | BlanketAgreementsContextValueLoading
  | BlanketAgreementsContextValueSuccess
  | BlanketAgreementsContextValueError

const initialValue: BlanketAgreementsContextValue = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  data: {
    id: '',
    current: undefined,
    all: [],
    currency: '',
  },
  refetch: Promise.resolve,
}

export const BlanketAgreementsContext = createContext<BlanketAgreementsContextValue>(initialValue)
