import { Box, Container, Stack } from '@chakra-ui/react'
import React from 'react'

import ArticleSidebar from 'components/elements/ArticleSidebar'
import ErrorBoundary from 'components/elements/ErrorBoundary'
import getLayoutMain, { LayoutMainProps } from 'components/layouts/Main'
import ArticleBreadcrumbs from 'components/modules/Article/ArticleBreadcrumbs'

export interface LayoutArticleProps extends LayoutMainProps {
  posts?: PostList[]
  currentPost?: CurrentPost
}

const TAG = 'LayoutArticle'

const styles = {
  wrapper: {
    direction: { base: 'column', lg: 'row' },
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    spacing: '6',
  },
  article: {
    maxW: 'desktop-content',
    minH: 'full',
    w: 'full',
    minW: '0',
  },
} as const

const LayoutArticle: LayoutComponent<LayoutArticleProps> = (props, page) => {
  return getLayoutMain(props)(
    <>
      <Container>
        <ArticleBreadcrumbs currentPost={props.currentPost} />
        <Stack id={TAG} data-testid={TAG} as={Stack} {...styles.wrapper}>
          <ArticleSidebar posts={props.posts} currentPost={props.currentPost} />
          <Box as="article" {...styles.article}>
            <ErrorBoundary>{page}</ErrorBoundary>
          </Box>
        </Stack>
      </Container>
    </>,
  )
}

function getLayoutArticle(props: LayoutArticleProps = {}): GetLayout {
  return (page) => LayoutArticle(props, page)
}

export default getLayoutArticle
