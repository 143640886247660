export const breakpoints = {
  sm: '480px', // 1x
  md: '720px', // 1.5x
  lg: '960px', // 2x
  xl: '1200px', // 2.5x
  '2xl': '1440px', // 3x
  '3xl': '1920px', // 4x
}

export type BreakpointKeys = keyof typeof breakpoints
