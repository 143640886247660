import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override NumberInput component configuration.
 * @link https://chakra-ui.com/docs/components/number-input
 * @default `src\node_modules\@chakra-ui\theme\dist\components\number-input.js`
 */
export const NumberInput: ThemeComponents['NumberInput'] = {
  defaultProps: {
    variant: 'outline',
  },
  variants: {
    outline: {
      field: {
        minW: '20',
        minH: '10',
        borderRadius: 0,
      },
      stepper: {
        borderRadius: 0,
      },
    },
  },
} as const
