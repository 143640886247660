import { SystemStyleInterpolation, Button, Icon } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useCallback, useContext } from 'react'

import ChevronIcon from 'assets/chevron_down.svg?component'
import { MenuDrawerContext } from 'components/modules/Header/MobileMenu/Provider/context'
import type { MainMenuLinkType } from 'config/menu'

/**
 * Used for E2E tests.
 */
const TAG = 'MenuListButton'

const styles = {
  button: {
    variant: 'unstyled',
    display: 'flex',
    fontSize: 'xl',
    fontWeight: 'normal',
    justifyContent: 'space-between',
    w: 'full',
    px: '4',
    my: '2',
    minH: '12',
  },
} as const satisfies SystemStyleInterpolation

const MenuListButton: FunctionComponent<MainMenuLinkType> = ({ translateKey, hasSubmenu, href }) => {
  const { t } = useTranslation()

  const { setCurrentMenu } = useContext(MenuDrawerContext)

  const handleOnClick = useCallback(() => setCurrentMenu(href), [href, setCurrentMenu])

  const name = t(translateKey as TranslationKeys['common'], void 0, {
    default: translateKey,
  })

  return hasSubmenu ? (
    <Button
      onClick={handleOnClick}
      rightIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize={3} transform="rotate(-90deg)" />}
      {...styles.button}
    >
      {name}
    </Button>
  ) : (
    <Button as={NextLink} href={href} {...styles.button}>
      {name}
    </Button>
  )
}

MenuListButton.displayName = TAG

export default MenuListButton
