import type { AccountInfo } from '@azure/msal-browser'
import type { Customer } from '@commercetools/platform-sdk'
import { createContext } from 'react'

export enum AuthStatus {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Loading = 'loading',
}

export interface Authenticated {
  /**
   * Contains the user's information from Azure.
   */
  user: AccountInfo
  /**
   * Contains the user's customer information from Commercetools.
   */
  customer: Customer
  /**
   * Indicates the status of the authentication.
   */
  status: AuthStatus.Authenticated
}

interface Unauthenticated {
  user: null
  customer: null
  status: AuthStatus.Unauthenticated
}

interface Loading {
  // Undetermined state
  user: null | AccountInfo
  // Undetermined state
  customer: null | Customer
  status: AuthStatus.Loading
}

export type AuthInitContextValue = Authenticated | Unauthenticated | Loading

export const initialValue: AuthInitContextValue = {
  user: null,
  customer: null,
  status: AuthStatus.Loading,
} as AuthInitContextValue

export const AuthInitContext = createContext<AuthInitContextValue>(initialValue)
