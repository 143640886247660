import { Button, Icon, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'

import ChevronIcon from 'assets/chevron_down.svg?component'
import UserIcon from 'assets/user.svg?component'
import { MenuDrawerContext } from 'components/modules/Header/MobileMenu/Provider/context'
import ShoppingListInfoButton from 'components/modules/Header/TopNavigationBar/ShoppingListInfoButton'
import { MenuDrawers } from 'config/menu'

/**
 * Used for E2E tests.
 */
const TAG = 'MenuItemAccount'

const styles = {
  accountButton: {
    variant: 'unstyled',
    display: 'flex',
    fontSize: 'md',
    justifyContent: 'space-between',
    w: 'full',
    px: '3',
  },
} as const satisfies SystemStyleInterpolation

const MenuItemAccount: FunctionComponent = () => {
  const { t } = useTranslation()
  const { setCurrentMenu } = useContext(MenuDrawerContext)

  return (
    <Stack my="8" fontSize="md" spacing="4">
      <ShoppingListInfoButton display={{ base: 'block' }} ml="4" fontWeight="bold" />

      <Button
        {...styles.accountButton}
        onClick={() => setCurrentMenu(MenuDrawers.accountMenu)}
        leftIcon={<Icon aria-hidden="true" as={UserIcon} boxSize="4" mr="2" />}
        rightIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize="3" transform="rotate(-90deg)" />}
      >
        <Text as="span" mr="auto">
          {t('components.header.yourAccountPages')}
        </Text>
      </Button>
    </Stack>
  )
}

MenuItemAccount.displayName = TAG

export default MenuItemAccount
