import type { ClientResponse, Customer } from '@commercetools/platform-sdk'

import { CT_MY_CLIENT } from 'commercetools/client/user'

interface Variables {
  customerVersion: number
  businessUnitKey: string
}

/**
 * Sets a business unit in a customer custom fields.
 * @link https://docs.commercetools.com/api/projects/me-profile#set-customfield
 */
function setCustomerBusinessUnit({ businessUnitKey, customerVersion }: Variables): Promise<ClientResponse<Customer>> {
  return CT_MY_CLIENT.me()
    .post({
      body: {
        actions: [{ action: 'setCustomField', name: 'selectedBusinessUnit', value: businessUnitKey }],
        version: customerVersion,
      },
    })
    .execute()
}

export default setCustomerBusinessUnit
