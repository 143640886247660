import React, { FunctionComponent, memo } from 'react'


import GoogleRecaptcha from 'components/modules/Google/Scripts/GoogleRecaptcha'


/**
 * Used for E2E tests.
 */
const TAG = 'GoogleScripts'

/**
 * `next/script` could not be used in `_document.page.tsx` as it states in documentation:
 * @link https://nextjs.org/docs/basic-features/script#script-component
 */
const GoogleScripts: FunctionComponent = () => (
  <>
    {/* <GoogleTagManager /> */}
    {/* <GoogleAnalytics /> */}
    <GoogleRecaptcha />
  </>
)

GoogleScripts.displayName = TAG

export default memo(GoogleScripts, () => true)
