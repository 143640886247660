/**
 * HOC for fetch with access token.
 */
function fetchWithToken(accessToken: string) {
  return (input: RequestInfo | URL, init?: RequestInit) => {
    const headers = new Headers(init?.headers)
    headers.set('Authorization', `Bearer ${accessToken}`)

    return fetch(input, { ...init, headers })
  }
}
export default fetchWithToken
