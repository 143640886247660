import { Flex, FlexProps } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'Badge'

interface BadgeProps extends FlexProps {
  count: number | string
}

const Badge: FunctionComponent<BadgeProps> = ({ count, ...props }) => {
  return (
    <Flex
      id={TAG}
      data-testid={TAG}
      alignItems="center"
      justifyContent="center"
      color="primary.white"
      fontWeight="bold"
      bg="primary.green"
      minW={{ base: '7', md: '8' }}
      h={{ base: '7', md: '8' }}
      fontSize={{ base: 'lg', md: 'sm' }}
      borderRadius="full"
      {...props}
    >
      {count}
    </Flex>
  )
}

Badge.displayName = TAG

export default Badge
