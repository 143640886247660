import { Stack } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import LogoutButton from 'components/modules/Header/LogoutButton'
import MobileMenuDrawer from 'components/modules/Header/MobileMenu/MobileMenuDrawer'
import SidebarLink from 'components/modules/Sidebar/SidebarLink'
import { useAccountMenu, MenuDrawers } from 'config/menu'

/**
 * Used for E2E tests.
 */
const TAG = 'MyAccountMenuContent'

const MyAccountMenuContent: FunctionComponent = () => {
  const accountMenu = useAccountMenu()

  return (
    <MobileMenuDrawer name={MenuDrawers.accountMenu}>
      <Stack as="nav" direction="column" spacing="4" fontSize="xl" px="10" py="4">
        {accountMenu?.map((link, index) => (
          <SidebarLink key={`${index}-${link.translateKey}`} {...link} color="primary.black" />
        ))}
        <LogoutButton variant="link" justifyContent="flex-start" fontSize="xl" color="primary.black" />
      </Stack>
    </MobileMenuDrawer>
  )
}

MyAccountMenuContent.displayName = TAG
export default MyAccountMenuContent
