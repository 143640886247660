import { Box, Icon, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronIcon from 'assets/chevron_right.svg?component'
import UserIcon from 'assets/user.svg?component'
import Link from 'components/elements/Link'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'

/**
 * Used for E2E tests.
 */
const TAG = 'UserInfoButton'

const styles = {
  link: {
    color: 'primary.white',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    paddingInline: '3',
    h: 'full',
  },
  icon: {
    boxSize: { base: '1rem', lg: '1.25rem' },
  },
  box: {
    display: { base: 'none', lg: 'contents' },
  },
  text: {
    lineHeight: '1.1',
    marginInlineStart: '3',
    fontSize: 'xs',
  },
  welcome: {
    fontWeight: 'bold',
  },
  chevron: {
    boxSize: '0.5rem',
    ml: '3',
  },
} as const satisfies SystemStyleInterpolation

const UserInfoButton: FunctionComponent = () => {
  const { t } = useTranslation()
  const { customer } = useAuthState(true)

  const welcome = customer?.firstName
    ? t('components.topbar.welcome', { name: customer.firstName })
    : t('components.topbar.welcomeNoName')
  const goToAccount = t('components.topbar.goToAccount')

  return (
    <Link {...styles.link} data-testid={TAG} rel="noreferrer" href={RoutePaths.profile} aria-label={goToAccount}>
      <Icon aria-hidden="true" as={UserIcon} {...styles.icon} />

      <Box {...styles.box}>
        <Box {...styles.text}>
          <Text {...styles.welcome}>{welcome}</Text>
          <Text>{goToAccount}</Text>
        </Box>
        <Icon aria-hidden="true" as={ChevronIcon} {...styles.chevron} />
      </Box>
    </Link>
  )
}

UserInfoButton.displayName = TAG

export default UserInfoButton
