import {
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode, useContext } from 'react'

import ChevronIcon from 'assets/chevron_down.svg?component'
import { MenuDrawerContext } from 'components/modules/Header/MobileMenu/Provider/context'

/**
 * Used for E2E tests.
 */
const TAG = 'MobileMenuDrawer'

interface MobileMenuDrawerProps extends BoxProps {
  name: string
  header?: ReactNode
  children: ReactNode
  showWelcome?: boolean
  btnRef?: React.RefObject<HTMLButtonElement>
}

const MobileMenuDrawer: FunctionComponent<MobileMenuDrawerProps> = ({
  name,
  btnRef,
  header,
  children,

  ...props
}) => {
  const { t } = useTranslation()

  const { currentMenu, closeMenu, backToMainMenu } = useContext(MenuDrawerContext)
  return (
    <Drawer
      id={`${TAG}-dialog`}
      data-testid={`${TAG}-dialog`}
      isOpen={name === currentMenu}
      placement="right"
      onClose={closeMenu}
      finalFocusRef={btnRef}
      size="full"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader alignItems="center" color="primary.blue">
          {header ? (
            header
          ) : (
            <Button
              variant="link"
              onClick={backToMainMenu}
              fontWeight="bold"
              fontSize="md"
              leftIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize="3" transform="rotate(90deg)" />}
            >
              {t('components.header.backToMain')}
            </Button>
          )}

          <DrawerCloseButton
            borderRadius="none"
            top="4"
            right="4"
            boxSize="9"
            borderWidth="2px"
            borderColor="primary.blue"
          />
        </DrawerHeader>

        <DrawerBody paddingBlock="0" paddingInline="0" {...props}>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

MobileMenuDrawer.displayName = TAG

export default MobileMenuDrawer
