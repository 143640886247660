import type { ClientResponse, ProductProjectionPagedSearchResponse } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'

import getRangeFacetFilters from 'commercetools/utils/getFiltersForRangeFacets'
import getInitialFilterQuery from 'commercetools/utils/getInitialFilterQuery'
import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

type QueryKey = [queryFnName: string, businessUnitKey: string, blanketAgreementsId: string, priceCurrency: string]

type ProductsQuery = Pick<QueryFunctionContext<QueryKey>, 'signal' | 'queryKey'>

/**
 * @returns empty products array and facets for all products
 * @link https://docs.commercetools.com/api/projects/products#query-products
 */
async function getInitialFacets({
  queryKey: [, businessUnitKey, blanketAgreementsId, priceCurrency],
}: ProductsQuery): Promise<ProductProjectionPagedSearchResponse> {
  if (!blanketAgreementsId) {
    throw new Error('Missing required blanket blanketAgreementsId to fetch categories')
  }
  const rangeFacetFilters = getRangeFacetFilters()

  const stringifiedParams = stringifyObjectValues({
    facet: ['categories.id', 'variants.attributes.erpTechspecWireType', ...rangeFacetFilters],
    'filter.query': getInitialFilterQuery(blanketAgreementsId),
    limit: 500,
    markMatchingVariants: true,
    priceCustomerGroup: blanketAgreementsId,
    priceCurrency: priceCurrency,
    businessUnitKey,
  })
  const params = new URLSearchParams(stringifiedParams).toString()

  const url = `${RoutePaths.productProjectionsSearchApi}?${params}`
  const response = await apiAuthFetch<ClientResponse<ProductProjectionPagedSearchResponse>>(url)

  return response.body
}

export default getInitialFacets
