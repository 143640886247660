import { Button, Icon, Skeleton, Stack, SystemStyleInterpolation, Text, useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useCallback } from 'react'

import CartIcon from 'assets/cart.svg?component'
import ChevronIcon from 'assets/chevron_right.svg?component'
import CartBadge from 'components/elements/CartBadge'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'
import useCart from 'utils/Providers/Cart/useCart'

/**
 * Used for E2E tests.
 */
const TAG = 'NavigationCart'

const styles = {
  loading: {
    h: '12',
    w: '176px',
    maxW: 'full',
  },
  stack: {
    direction: 'row',
    justifyContent: 'flex-end',
    display: { base: 'none', lg: 'flex' },
  },
  cart: {
    variant: 'unstyled',
    display: 'inline-flex',
    color: 'primary.blue',
    _hover: {
      span: {
        textDecoration: 'underline',
      },
    },
    _focusVisible: {
      span: {
        textDecoration: 'underline',
      },
    },
  },
  checkout: {
    border: 'none',
    px: '0',
    role: 'status',
    display: 'inline-flex',
    color: 'primary.blue',
    _hover: { textDecoration: 'underline' },
    _focusVisible: { textDecoration: 'underline' },
  },
} as const satisfies SystemStyleInterpolation

const NavigationCart: FunctionComponent = () => {
  const { t } = useTranslation()

  const { status } = useAuthState()
  const toast = useToast()
  const { data: cart } = useCart()
  const lineItemsLength = cart?.lineItems?.length || 0

  const router = useRouter()

  const handleGoToCart = useCallback(() => {
    if (lineItemsLength > 0) {
      router.push(RoutePaths.cart)
    } else {
      toast({
        title: t('components.cart.alerts.yourCartIsEmpty'),
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }, [lineItemsLength, router, t, toast])

  if (status === AuthStatus.Loading) {
    return (
      <Stack {...styles.stack}>
        <Skeleton {...styles.loading} />
      </Stack>
    )
  }

  return (
    <Stack {...styles.stack}>
      <Button
        data-testid={`${TAG}-button-cart`}
        aria-label={t('components.header.goToCart')}
        leftIcon={<Icon aria-hidden="true" as={CartIcon} boxSize={6} color="primary.black" />}
        rightIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize={2} />}
        {...styles.cart}
        onClick={handleGoToCart}
      >
        <CartBadge />
        <Text as="span" ml="2">
          {t('components.header.goToCart')}
        </Text>
      </Button>
    </Stack>
  )
}

NavigationCart.displayName = TAG

export default NavigationCart
