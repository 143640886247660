import { Box, Button, Collapse, Icon, SystemStyleInterpolation, Text, useDisclosure } from '@chakra-ui/react'
import React, { ComponentProps, FunctionComponent, useId } from 'react'

import MinusIcon from 'assets/minus.svg?component'
import PlusIcon from 'assets/plus.svg?component'
import MenuList from 'components/elements/MenuList'

interface MenuCollapseProps extends ComponentProps<typeof MenuList> {
  title: string
}

const TAG = 'MenuCollapse'

const styles = {
  button: {
    w: '100%',
    maxWidth: '100%',
    display: { base: 'flex', md: 'none' },
    justifyContent: 'space-between',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'primary.blue',
    fontSize: 'lg',
    height: 'auto',
    minHeight: '1.75rem',
  },
  icon: {
    display: { md: 'none' },
  },
  title: {
    display: { base: 'none', md: 'block' },
    fontWeight: 'bold',
    mb: '2',
  },
  collapse: {
    overflow: { md: 'visible' },
    sx: {
      '& > div': {
        // !important is required, because Collapse uses inline styles
        overflow: { md: 'visible !important' },
        height: { md: 'auto !important' },
        opacity: { md: '1 !important' },
        display: { md: 'block !important' },
      },

      '& ul': {
        marginBlockStart: { base: '2', md: '0' },
      },
    },
  },
  stack: {
    direction: 'column',
    spacing: '2',
  },
} as const satisfies SystemStyleInterpolation

/**
 * Menu that collapses on mobile and expands on desktop
 */
const MenuCollapse: FunctionComponent<MenuCollapseProps> = ({ title, ...props }) => {
  const { isOpen, onToggle } = useDisclosure()

  const reactId = useId()
  const buttonId = `${TAG}-button-${reactId}`
  const collapseId = `${TAG}-collapse-${reactId}`

  return (
    <>
      <Button
        {...styles.button}
        data-testid={TAG}
        variant="unstyled"
        id={buttonId}
        aria-expanded={isOpen}
        aria-controls={collapseId}
        onClick={onToggle}
        rightIcon={<Icon {...styles.icon} aria-hidden="true" as={isOpen ? MinusIcon : PlusIcon} />}
      >
        {title}
      </Button>
      <Text as="span" {...styles.title}>
        {title}
      </Text>

      <Box {...styles.collapse} id={collapseId} aria-labelledby={buttonId} role="region">
        <Collapse in={isOpen}>
          <MenuList {...styles.stack} {...props} />
        </Collapse>
      </Box>
    </>
  )
}

MenuCollapse.displayName = TAG

export default MenuCollapse
