import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Custom form select component
 * @see `src\components\elements\FormSelect\index.tsx`
 *
 * @link https://chakra-ui.com/docs/components/form-control
 * @default `src\node_modules\@chakra-ui\theme\dist\components\select.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-control.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-label.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-error.js`
 */
export const FormSelect = {
  variants: {
    simple: {
      control: {},
      label: {
        fontWeight: 'bold',
        fontSize: 'sm',
        lineHeight: 1.71,
      },
      input: {
        borderColor: 'gray.100',
        borderRadius: 0,
        minHeight: '12',
        iconSize: '8',
        iconColor: 'currentColor',
      },
      error: {},
    },
  },
} as const satisfies ThemeComponents['FormSelect']
