import type { SilentRequest } from '@azure/msal-browser';
import { RoutePaths } from 'config/routes';
import fetchWithToken from 'utils/fetchWithToken';
import { msalInstance } from 'utils/Providers/Auth';

const providedGrantHasExpiredErrorCodes = ['AADB2C90080', 'invalid_grant'];

// Type guard to check if the error has the expected shape
function isAuthError(error: unknown): error is { errorCode: string; message: string } {
  return typeof error === 'object' && error !== null && 'errorCode' in error && 'message' in error;
}

export async function getAccessToken(tokenRequest: SilentRequest): Promise<string> {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error('No active account');
  }

  try {
    const response = await msalInstance.acquireTokenSilent({ ...tokenRequest, account });
    return response.accessToken;
  } catch (error) {
    if (isAuthError(error) && providedGrantHasExpiredErrorCodes.includes(error.errorCode)) {
      msalInstance.logoutRedirect({ account, postLogoutRedirectUri: RoutePaths.login });
    }
    throw new Error('There is a problem with access token');
  }
}

const tenant = process.env.AZURE_AD_B2C_TENANT_NAME;
const scopesId = process.env.AZURE_AD_B2C_SCOPES_ID;

/**
 * Client side fetch with access token and scopes
 */
export async function fetchWithAzureToken(scopes: string[], input: RequestInfo | URL, init?: RequestInit) {
  const ordersTokenRequest: SilentRequest = {
    scopes: scopes.map(scope => `https://${tenant}.onmicrosoft.com/${scopesId}/${scope}`),
    forceRefresh: true,
  };

  try {
    const accessToken = await getAccessToken(ordersTokenRequest);
    return fetchWithToken(accessToken)(input, init);
  } catch (error) {
    // Additional error handling logic if needed
    throw error;
  }
}
