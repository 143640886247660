import { useRouter } from 'next/router'
import { FunctionComponent, useEffect } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'RouteChange'

const reloadOTBanner = () => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk')
  if (otConsentSdk) {
    otConsentSdk.remove()
  }

  if (window.OneTrust !== null) {
    window.OneTrust?.Init()

    setTimeout(function () {
      window.OneTrust?.LoadBanner()

      const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings') as HTMLCollectionOf<HTMLElement>
      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation()
          window.OneTrust?.ToggleInfoDisplay()
        }
      }
    }, 1000)
  }
}

const RouteChange: FunctionComponent = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      reloadOTBanner()
      window.dataLayer?.push({ event: 'PageView', page_path: url, page_title: document.title })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return null
}

RouteChange.displayName = TAG

export default RouteChange
