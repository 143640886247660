import { useRouter } from 'next/router'
import React, { FunctionComponent, memo } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import CartCheckoutHeader from 'components/modules/Header/CartCheckoutHeader'
import HeaderAuthenticated from 'components/modules/Header/HeaderAuthenticated'
import HeaderUnauthenticated from 'components/modules/Header/HeaderUnauthenticated'
import { NOT_LOGGED_IN_ROUTES, RoutePaths } from 'config/routes'

const CART_CHECKOUT_ROUTES = [RoutePaths.cart, RoutePaths.checkout]

const Header: FunctionComponent = () => {
  const { status } = useAuthState()
  const isNotAuthenticated = status === AuthStatus.Unauthenticated
  const { route: currentRoute } = useRouter()

  const isCartRoute = CART_CHECKOUT_ROUTES.some((route) => route === `${currentRoute}/`)
  if (isCartRoute) {
    return <CartCheckoutHeader />
  }

  if (status === AuthStatus.Loading) {
    // User is on route e.g. `/login` so we asume that he is not authenticated
    // but when he is on route e.g. `/my/profile` we could assume that he will be authenticated
    const isAuthRoute =
      currentRoute !== RoutePaths.home &&
      Object.values(NOT_LOGGED_IN_ROUTES).some((route) => route.startsWith(currentRoute))

    if (isAuthRoute) {
      return <HeaderUnauthenticated />
    }
    return <HeaderAuthenticated />
  }

  return isNotAuthenticated ? <HeaderUnauthenticated /> : <HeaderAuthenticated />
}

export default memo(Header)
