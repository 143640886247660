import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override modal component configuration.
 * @link https://chakra-ui.com/docs/components/modal
 * @default `src\node_modules\@chakra-ui\theme\dist\components\modal.js`
 */
export const Modal: ThemeComponents['Modal'] = {
  baseStyle: {
    header: {
      color: 'primary.red',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '2xl',
      lineHeight: 1.25,
      paddingBlockStart: '12',
      paddingInline: { base: '4', md: '6' },
    },
    body: {
      paddingBlockStart: '4',
      paddingBlockEnd: '2',
      paddingInline: { base: '6', md: '20' },
    },
    footer: {
      paddingBlockStart: { base: '6', md: '2' },
      paddingBlockEnd: { base: '8', md: '16' },
      paddingInline: { base: '6', md: '20' },
      flexDirection: 'column',
      alignItems: 'flex-start',
      // Submit button
      '& > :is(a, button):first-of-type': {
        width: '100%',
        minHeight: '12',
        marginBlockEnd: '4',
      },
      // Cancel button
      '& > :is(a, button):nth-of-type(2)': {
        paddingBlock: '2',
      },
    },
  },
} as const
