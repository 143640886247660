import { Skeleton, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import ChangeCompanyButton from 'components/modules/Header/ChangeCompany/ChangeCompanyButton'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

/**
 * Used for E2E tests.
 */
const TAG = 'ChangeCompany'

const styles = {
  loading: {
    h: '4',
    w: { base: '90px', lg: '150px' },
  },
} as const satisfies SystemStyleInterpolation

const ChangeCompany: FunctionComponent = () => {
  const { status, customer } = useAuthState()
  const businessUnit = useBusinessUnit()

  if (status === AuthStatus.Loading) {
    return <Skeleton {...styles.loading} />
  }

  if (status === AuthStatus.Unauthenticated) {
    return null
  }

  // When there's only one company, no need to show the button to change it.
  const hasMoreThanOneCompany = businessUnit.data.all.length > 1

  const companyName = businessUnit.data.current?.name || customer.companyName

  return <ChangeCompanyButton companyName={companyName} hasMoreThanOneCompany={hasMoreThanOneCompany} />
}

ChangeCompany.displayName = TAG

export default ChangeCompany
