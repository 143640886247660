import { Breadcrumb, BreadcrumbItem, Icon } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import ChevronDownIcon from 'assets/chevron_down.svg?component'
import Link from 'components/elements/Link'

export interface BreadcrumbItemProps {
  id: string
  name: string
  href: string
}
interface BreadcrumbsProps {
  breadcrumbs: ReadonlyArray<BreadcrumbItemProps>
}

/**
 * Used for E2E tests.
 */
const TAG = 'Breadcrumbs'

const SEPARATOR = <Icon aria-hidden="true" as={ChevronDownIcon} boxSize="2" transform="rotate(-90deg)" />

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ breadcrumbs, ...props }) => (
  <Breadcrumb my="4" separator={SEPARATOR}>
    {breadcrumbs.map((item, index) => {
      const isLast = index === breadcrumbs.length - 1

      return (
        <BreadcrumbItem key={item.id} isCurrentPage={isLast} isLastChild={isLast} {...props}>
          <Link href={item.href} aria-current={isLast ? 'page' : void 0}>
            {item.name}
          </Link>
        </BreadcrumbItem>
      )
    })}
  </Breadcrumb>
)

Breadcrumbs.displayName = TAG

export default Breadcrumbs
