import { Box, Container, Flex, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import BackToTheStore from 'components/modules/Header/CartCheckoutHeader/BackToTheStore'
import ContactEmail from 'components/modules/Header/CartCheckoutHeader/ContactEmail'
import Logo from 'components/modules/Header/Logo'

const TAG = 'CartCheckoutHeader'

const styles = {
  header: {
    w: 'full',
    backgroundColor: 'primary.white',
    boxShadow: 'sm',
    minH: { base: '6rem', lg: '7.5rem' },
    borderBlockEnd: '1px solid',
    borderColor: 'gray.100',
  },
  headerContainer: {
    h: 'full',
    px: { base: 4, md: 8 },
    paddingBlockStart: { base: 4, lg: 8 },
    paddingBlockEnd: { base: 4, lg: 6 },
    justifyContent: 'space-between',
    alignItems: { base: 'flex-start', lg: 'flex-end' },
    flexDirection: { base: 'column', lg: 'row' },
  },
  logo: {
    alignSelf: { lg: 'center' },
    marginBlockEnd: { base: 2, lg: 0 },
  },
} as const satisfies SystemStyleInterpolation

const CartCheckoutHeader: FunctionComponent = () => (
  <>
    <Box as="header" data-testid={`${TAG}-header`} {...styles.header}>
      <Container as={Flex} {...styles.headerContainer}>
        <Box {...styles.logo}>
          <Logo />
        </Box>
        <ContactEmail />
      </Container>
    </Box>
    <BackToTheStore />
  </>
)

CartCheckoutHeader.displayName = TAG

export default CartCheckoutHeader
