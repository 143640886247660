import { Box, Container } from '@chakra-ui/react'
import React, { memo } from 'react'

import Logo from 'components/modules/Header/Logo'
import MobileMenu from 'components/modules/Header/MobileMenu'
import NavigationBar from 'components/modules/Header/NavigationBar'
import NavigationCart from 'components/modules/Header/NavigationCart'
import { styles } from 'components/modules/Header/style'
import TopNavigationBar from 'components/modules/Header/TopNavigationBar'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'

const HeaderAuthenticated = () => {
  const capabilities = useBusinessUnitCapabilitites()

  return (
    <>
      <TopNavigationBar />
      <Box as="header" {...styles.header}>
        <Container {...styles.headerContainer}>
          <Logo />
          <MobileMenu />
          {capabilities.canPutOrder && <NavigationCart />}
        </Container>
      </Box>
      <NavigationBar />
    </>
  )
}

export default memo(HeaderAuthenticated)
