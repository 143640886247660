import { Box, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import MenuList from 'components/elements/MenuList'
import { FOOTER_MENU_BOTTOM_ROW } from 'components/modules/Footer/config'

const TAG = 'BottomRowMenu'

const styles = {
  nav: {
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: { base: 'full', md: '75%' },
  },
  stack: {
    direction: { base: 'column', md: 'row' },
    spacing: { base: '4', lg: '6', xl: '10' },
    justifyContent: { base: 'flex-start', md: 'flex-end' },
  },
} as const satisfies SystemStyleInterpolation

const BottomRowMenu: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Box as="nav" data-testid={TAG} aria-label={t('components.footer.bottomRow.label')} {...styles.nav}>
      <MenuList menu={FOOTER_MENU_BOTTOM_ROW} {...styles.stack} />
    </Box>
  )
}

BottomRowMenu.displayName = TAG

export default BottomRowMenu
