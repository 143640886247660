/**
 * The main goal of this function is to set user logged state in the DOM - html.
 * This function will not be called in SSR, so default (false) state was set in the {@see _document}.
 *
 * @see ConditionalRenderingLoggedIn
 * @see ConditionalRenderingNotLoggedIn
 *
 * @param isLoggedIn user logged state
 */
function setStateInHtml(isLoggedIn: boolean): void {
  document.documentElement.setAttribute('data-is-logged', `${isLoggedIn}`)
}

export default setStateInHtml
