import { NavigationClient, type NavigationOptions } from '@azure/msal-browser'
import Router from 'next/router'

/**
 * MSAL should understand that we are using Next.js and Next.js routing system.
 * We ovveride the `navigateInternal` method to use Next.js router instead of the default one (window.location).
 * Thanks to this, our app will be more performant and we will not have to reload the whole app when we navigate to a new page,
 * but only the page we are navigating to.
 *
 * @link https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.navigationclient.html
 */
class CustomNavigationClient extends NavigationClient {
  /**
   * @inheritdoc
   *
   * @returns false if this doesn't cause the page to reload
   */
  async navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
    // Remove the origin from the URL to make it relative as it should be relative.
    const relativePath = url.replace(window.location.origin, '')
    if (options.noHistory) {
      Router.replace(relativePath)
    } else {
      Router.push(relativePath)
    }
    return false
  }
}

export default CustomNavigationClient
