export const Radio = {
  parts: ['control', 'label'],

  baseStyle: {
    control: {
      borderWidth: 1,
      borderColor: 'gray.400',
      _checked: {
        bg: 'white',
        borderColor: 'primary.black',
        _hover: {
          bg: 'transparent',
        },
        _focus: {
          boxShadow: 'none',
        },
        _before: {
          bg: 'primary.red',
          w: 2,
          h: 2,
        },
      },
    },
  },

  defaultProps: {
    colorScheme: 'gray',
  },
  sizes: {
    md: {
      label: { w: 'full', fontSize: 'sm' },
    },
  },
}
