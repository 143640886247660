import type { BusinessUnitPagedQueryResponse, ClientResponse } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'

import { CT_MY_CLIENT } from 'commercetools/client/user'

type MyBusinessUnit = Pick<QueryFunctionContext, 'signal'>

/**
 * @returns my business unit.
 * @link https://docs.commercetools.com/api/projects/me-business-units
 */
function getMyBusinessUnits({ signal }: MyBusinessUnit): Promise<ClientResponse<BusinessUnitPagedQueryResponse>> {
  return CT_MY_CLIENT.me().businessUnits().get({
    queryArgs: {
      // Add your query parameters here
      limit: 500,
      offset: 0
    }
  }).execute({ signal });  // Execute the request with the signal
}

export default getMyBusinessUnits
