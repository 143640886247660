import type { ClientResponse, CustomerGroupPagedQueryResponse } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'

import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

type QueryKey = [queryFnName: string, businessUnitKey: string]

type BlanketAgreementsList = Pick<QueryFunctionContext<QueryKey>, 'queryKey'>

async function getBlanketAgreements({
  queryKey: [, businessUnitKey],
}: BlanketAgreementsList): Promise<ClientResponse<CustomerGroupPagedQueryResponse>> {
  const stringifiedParams = stringifyObjectValues({ businessUnitKey })
  const params = new URLSearchParams(stringifiedParams).toString()
  const url = `${RoutePaths.customerGroupsApi}?${params}`
  return apiAuthFetch<ClientResponse<CustomerGroupPagedQueryResponse>>(url)
}

export default getBlanketAgreements
