import { useQuery } from '@tanstack/react-query'
import React, { FunctionComponent, ReactNode, useMemo } from 'react'

import getBlanketAgreements from 'commercetools/blanketAgreements/getBlanketAgreements'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useMSALSessionStatus from 'components/modules/Auth/hooks/useMSALSession'
import { BlanketAgreementsContext, BlanketAgreementsContextValue } from 'utils/Providers/BlanketAgreements/context'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'
import sortText from 'utils/sortText'

interface BlanketAgreementsProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'BlanketAgreementsProvider'

const BlanketAgreementsProviderInner: FunctionComponent<BlanketAgreementsProviderProps> = ({ children }) => {
  const msalStatus = useMSALSessionStatus()
  const {
    data: { key: businessUnitKey },
  } = useBusinessUnit()

  const response = useQuery(['getBlanketAgreements', businessUnitKey], getBlanketAgreements, {
    enabled: msalStatus === AuthStatus.Authenticated && !!businessUnitKey,
  })

  const value = useMemo<BlanketAgreementsContextValue>(() => {
    const myBlanketAgreements = response.data?.body.results || []

    const defaultBlanketAgreements = myBlanketAgreements[0]

    return {
      data: {
        all: myBlanketAgreements.sort((a, b) => sortText(a.name, b.name)),
        current: defaultBlanketAgreements,
        currency: defaultBlanketAgreements?.custom?.fields.erpCurrency,
        id: defaultBlanketAgreements?.id,
      },
      isSuccess: response.isSuccess,
      isLoading: response.isLoading,
      isError: response.isError,
      refetch: response.refetch,
    } as BlanketAgreementsContextValue
  }, [response.data?.body.results, response.isError, response.isLoading, response.isSuccess, response.refetch])

  return <BlanketAgreementsContext.Provider value={value}>{children}</BlanketAgreementsContext.Provider>
}

const BlanketAgreementsProvider: FunctionComponent<BlanketAgreementsProviderProps> = ({ children }) => {
  const businessUnitCapabilitites = useBusinessUnitCapabilitites()

  if (businessUnitCapabilitites?.canPutOrder) {
    return <BlanketAgreementsProviderInner>{children}</BlanketAgreementsProviderInner>
  } else {
    return <>{children}</>
  }
}

BlanketAgreementsProvider.displayName = TAG

export default BlanketAgreementsProvider
