import type { Configuration, RedirectRequest, SilentRequest } from '@azure/msal-browser'

/**
 * @link https://columbus-dc.atlassian.net/wiki/spaces/KAN/pages/128844270/User+roles
 */
export enum UserRoles {
  CompanyUser = 'customer-user',
  CompanyAdmin = 'customer-superuser',
  KanthalUser = 'kanthal-sales-user',
  KanthalAdmin = 'kanthal-admin-user',
}

export type UserRolesKeys = keyof typeof UserRoles
export type UserRolesValues = (typeof UserRoles)[UserRolesKeys]

export const ADMIN_ROLES = [UserRoles.KanthalAdmin, UserRoles.KanthalUser, UserRoles.CompanyAdmin]

const tenant = process.env.AZURE_AD_B2C_TENANT_NAME
const userFlow = process.env.AZURE_AD_B2C_USER_FLOW
const clientId = process.env.AZURE_AD_B2C_CLIENT_ID
const redirectUri = process.env.AZURE_AD_B2C_REDIRECT_URI

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${userFlow}`,
    knownAuthorities: [`${tenant}.b2clogin.com`],
    redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: true,
  },
}

/**
 * Options used to login user.
 * @see LoginPage
 */
export const loginRequest: RedirectRequest = {
  redirectUri: process.env.NEXT_PUBLIC_SITE_URL,
  scopes: ['openid', 'profile', `https://${tenant}.onmicrosoft.com/web/user_impersonation`],
}

/**
 * Options used to acquire currently logged in user access token silently.
 */
export const tokenRequest: SilentRequest = {
  scopes: ['openid', 'offline_access', clientId],
  forceRefresh: false,
}

/**
 *  Scopes used to fetch data from azure
 */

export const AzureScopes = {
  Orders: ['order.read', 'orders.read'],
  Delivery: ['deliveryform.read'],
  MetalSurcharge: ['metalsurcharges.read'],
}
