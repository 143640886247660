import { ChakraTheme, extendTheme, theme as base } from '@chakra-ui/react'

import { Alert } from 'theme/components/alert'
import { Button } from 'theme/components/button'
import { Checkbox } from 'theme/components/checkbox'
import { Container } from 'theme/components/container'
import { Divider } from 'theme/components/divider'
import { FormInput } from 'theme/components/formInput'
import { FormLabel } from 'theme/components/formLabel'
import { FormSelect } from 'theme/components/formSelect'
import { Heading } from 'theme/components/heading'
import { Input } from 'theme/components/input'
import { Link } from 'theme/components/link'
import { Menu } from 'theme/components/menu'
import { Modal } from 'theme/components/modal'
import { NumberInput } from 'theme/components/numberInput'
import { Radio } from 'theme/components/radio'
import { Select } from 'theme/components/select'
import { Table } from 'theme/components/table'

import { breakpoints } from './foundations/breakpoints'
import { colors } from './foundations/colors'
import { fontSizes } from './foundations/font-sizes'
import { sizes } from './foundations/sizes'
import { global } from './global'

const overrides: Partial<ChakraTheme> = {
  config: {
    cssVarPrefix: 'kt',
  },
  styles: {
    global,
  },
  breakpoints,
  colors,
  sizes,
  fonts: {
    heading: `DIN Pro, DIN Pro Fallback, ${base.fonts?.heading}`,
    body: `DIN Pro, DIN Pro Fallback, ${base.fonts?.body}`,
  },
  fontSizes,
  components: {
    Alert,
    Button,
    Checkbox,
    Container,
    Divider,
    FormInput,
    FormLabel,
    FormSelect,
    Heading,
    Input,
    Link,
    Menu,
    Modal,
    NumberInput,
    Radio,
    Table,
    Select,
  },
}

export default extendTheme(overrides)
