import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useMemo } from 'react'

import Breadcrumbs from 'components/elements/Breadcrumbs'
import type { BreadcrumbItemProps } from 'components/elements/Breadcrumbs'
import { MAIN_POSTS_PAGE_NAME } from 'config/posts'
import { RoutePaths } from 'config/routes'
import normalizePathname from 'utils/normalizePathname'

interface ArticleBreadcrumbsProps {
  currentPost?: CurrentPost
}

/**
 * Used for E2E tests.
 */
const TAG = 'ArticleBreadcrumbs'

const ArticleBreadcrumbs: FunctionComponent<ArticleBreadcrumbsProps> = ({ currentPost }) => {
  const { t } = useTranslation()

  const breadcrumbs = useMemo<Array<BreadcrumbItemProps>>(() => {
    const _breadcrumbs: Array<BreadcrumbItemProps> = [
      { id: 'home', name: t('components.breadcrumbs.home'), href: RoutePaths.home },
      { id: 'about-this-site', name: t('components.breadcrumbs.aboutThisSite'), href: RoutePaths.about },
    ]
    if (currentPost && !currentPost.slug.includes(MAIN_POSTS_PAGE_NAME)) {
      const _slug = normalizePathname(`/${RoutePaths.about + currentPost.slug}/`)
      _breadcrumbs.push({ id: currentPost.slug, name: currentPost.title, href: _slug })
    }
    return _breadcrumbs
  }, [currentPost, t])

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}

ArticleBreadcrumbs.displayName = TAG

export default ArticleBreadcrumbs
