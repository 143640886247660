import { useQuery } from '@tanstack/react-query'
import React, { FunctionComponent, ReactNode, useMemo } from 'react'

import getMyBusinessUnits from 'commercetools/businessUnits/getMyBusinessUnits'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useMSALSessionStatus from 'components/modules/Auth/hooks/useMSALSession'
import contactEmails from 'config/files/contactEmails.json'
import { BusinessUnitContext, BusinessUnitContextValue } from 'utils/Providers/BusinessUnit/context'
import useMyBusinessUnitKey from 'utils/Providers/BusinessUnit/useMyBusinessUnitKey'
import sortText from 'utils/sortText'

interface BusinessUnitProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'BusinessUnitProvider'

const BusinessUnitProvider: FunctionComponent<BusinessUnitProviderProps> = ({ children }) => {
  const msalStatus = useMSALSessionStatus()

  const response = useQuery(['getMyBusinessUnits'], getMyBusinessUnits, {
    enabled: msalStatus === AuthStatus.Authenticated,
  })

  const businessUnitKey = useMyBusinessUnitKey(response.data?.body.results?.[0]?.key)

  const value = useMemo<BusinessUnitContextValue>(() => {
    const myBusinessUnits = (response.data?.body.results || []).filter((bu) => bu.status === 'Active')

    const defaultBusinessUnit = myBusinessUnits[0]
    const current = myBusinessUnits.find((bu) => bu.key === businessUnitKey) || defaultBusinessUnit
    const contactEmail = contactEmails.find((_) => _.country === current?.custom?.fields?.erpCountry)?.email

    return {
      data: {
        all: myBusinessUnits.sort((a, b) => sortText(a.name, b.name)),
        contactEmail,
        current,
        key: businessUnitKey,
      },
      isSuccess: response.isSuccess,
      isLoading: response.isLoading,
      isError: response.isError,
      refetch: response.refetch,
    } as BusinessUnitContextValue
  }, [
    businessUnitKey,
    response.data?.body.results,
    response.isError,
    response.isLoading,
    response.isSuccess,
    response.refetch,
  ])

  return <BusinessUnitContext.Provider value={value}>{children}</BusinessUnitContext.Provider>
}

BusinessUnitProvider.displayName = TAG

export default BusinessUnitProvider
