import { Button, Icon } from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { ComponentProps, FunctionComponent } from 'react'

import ChevronIcon from 'assets/chevron_right.svg?component'

type InheritedProps = Omit<ComponentProps<typeof Button>, 'as'>

interface ButtonBackProps extends InheritedProps {
  url: string
}

/**
 * Used for E2E tests.
 */
const TAG = 'ButtonBack'

const ButtonBack: FunctionComponent<ButtonBackProps> = ({ children, url, ...props }) => (
  <Button
    variant="link"
    leftIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize={2} transform="rotate(180deg)" />}
    {...props}
    as={NextLink}
    href={url}
  >
    {children}
  </Button>
)

ButtonBack.displayName = TAG

export default ButtonBack
