import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useEffect } from 'react'

import SectionLoading from 'components/elements/SectionLoading'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import useRedirectTo from 'hooks/useRedirectTo'

interface GuardNotAuthorizedProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'GuardNotAuthorized'

const GuardNotAuthorized: FunctionComponent<GuardNotAuthorizedProps> = ({ children }) => {
  const { status } = useAuthState()
  const { isReady, push } = useRouter()

  const redirectTo = useRedirectTo()

  useEffect(() => {
    if (isReady && status === AuthStatus.Authenticated) {
      push(redirectTo)
    }
  }, [isReady, push, redirectTo, status])

  if (status !== AuthStatus.Authenticated) {
    return <>{children}</>
  }

  return <SectionLoading />
}

GuardNotAuthorized.displayName = TAG

export default GuardNotAuthorized
