import { Box, Icon, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronDownIcon from 'assets/chevron_down.svg?component'
import GuardRole from 'components/modules/Auth/GuardRole'
import ChangeCompanyTrigger from 'components/modules/Header/ChangeCompany/ChangeCompanyTrigger'
import { UserRoles } from 'config/auth'

interface ChangeCompanyButtonProps {
  companyName: string | undefined
  hasMoreThanOneCompany: boolean
}

/**
 * Used for E2E tests.
 */
const TAG = 'ChangeCompanyButton'

/**
 * Only Kanthal users can change company.
 * @link https://columbus-dc.atlassian.net/wiki/spaces/KAN/pages/128844270/User+roles
 */
const CAN_CHANGE_COMPANY_ROLES = [UserRoles.KanthalAdmin, UserRoles.KanthalUser] as const

const styles = {
  desktop: {
    display: {
      base: 'none',
      lg: 'flex',
    },
    align: 'center',
    direction: 'row',
    spacing: '3',
  },
  mobile: {
    minWidth: 0,
    paddingInlineEnd: '2',
    display: { base: 'flex', lg: 'none' },
  },
  company: {
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  divider: {
    h: '4',
    w: '1px',
    opacity: '0.5',
    bg: 'primary.white',
  },
  chevron: {
    marginInlineStart: '2',
    boxSize: '2',
  },
} as const satisfies SystemStyleInterpolation

const ChangeCompanyButton: FunctionComponent<ChangeCompanyButtonProps> = ({ companyName, hasMoreThanOneCompany }) => {
  const { t } = useTranslation()

  const company = <Text {...styles.company}>{companyName}</Text>

  if (!companyName) {
    return <Box />
  }

  return (
    <>
      <Box data-testid={`${TAG}-mobile`} {...styles.mobile}>
        {hasMoreThanOneCompany ? (
          <GuardRole level="customer" allowedRoles={CAN_CHANGE_COMPANY_ROLES} fallback={company}>
            <ChangeCompanyTrigger>
              {company}
              <Icon aria-hidden="true" as={ChevronDownIcon} {...styles.chevron} />
            </ChangeCompanyTrigger>
          </GuardRole>
        ) : (
          company
        )}
      </Box>

      <Stack data-testid={`${TAG}-desktop`} {...styles.desktop}>
        {company}

        <GuardRole level="customer" allowedRoles={CAN_CHANGE_COMPANY_ROLES}>
          {hasMoreThanOneCompany ? (
            <>
              <Box {...styles.divider} />
              <ChangeCompanyTrigger>{t('components.topbar.changeCompany')}</ChangeCompanyTrigger>
            </>
          ) : null}
        </GuardRole>
      </Stack>
    </>
  )
}

ChangeCompanyButton.displayName = TAG

export default ChangeCompanyButton
