import type { BusinessUnit } from '@commercetools/platform-sdk'
import type { UseQueryResult } from '@tanstack/react-query'
import { createContext } from 'react'

interface BusinessUnitContextValueBase {
  refetch: UseQueryResult<BusinessUnit>['refetch']
}

interface BusinessUnitContextValueLoading extends BusinessUnitContextValueBase {
  isSuccess: false
  isLoading: true
  isError: false
  data: {
    key: string
    contactEmail: string | undefined
    current: BusinessUnit | undefined
    all: BusinessUnit[]
  }
}

interface BusinessUnitContextValueSuccess extends BusinessUnitContextValueBase {
  isSuccess: true
  isLoading: false
  isError: false
  data: {
    key: string
    contactEmail: string | undefined
    current: BusinessUnit | undefined
    all: BusinessUnit[]
  }
}

interface BusinessUnitContextValueError extends BusinessUnitContextValueBase {
  isSuccess: false
  isLoading: false
  isError: true
  data: {
    key: string
    contactEmail: undefined
    current: undefined
    all: []
  }
}

export type BusinessUnitContextValue =
  | BusinessUnitContextValueLoading
  | BusinessUnitContextValueSuccess
  | BusinessUnitContextValueError

const initialValue: BusinessUnitContextValue = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  data: {
    key: '',
    contactEmail: '',
    current: undefined,
    all: [],
  },
  refetch: Promise.resolve,
}

export const BusinessUnitContext = createContext<BusinessUnitContextValue>(initialValue)
