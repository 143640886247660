import { Menu, MenuButton, Button, Icon, MenuList, MenuItem, Box } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronDownIcon from 'assets/chevron_down.svg?component'
import isRootCategory from 'commercetools/utils/isRootCategory'
import { RoutePaths } from 'config/routes'
import useInitialFacets from 'hooks/useInitialFacets'
import useLocalizedString from 'hooks/useLocalizedString'
import assignParamsToUrl from 'utils/assignParamsToUrl'

const TAG = 'NavigationCategories'

const NavigationCategories: FunctionComponent = () => {
  const { t } = useTranslation()

  const display = useLocalizedString()
  const { categories } = useInitialFacets()

  return (
    <Box>
      <Menu>
        <MenuButton
          role="listbox"
          as={Button}
          textTransform="inherit"
          variant="menu"
          pl="0"
          pr="4"
          rightIcon={<Icon aria-hidden="true" as={ChevronDownIcon} boxSize="2" ml="2" />}
        >
          {t('components.header.categories')}
        </MenuButton>
        <MenuList>
          {categories?.filter(isRootCategory)?.map((category) => (
            <MenuItem
              key={display(category.name)}
              as={NextLink}
              href={
                category.isMainRoot
                  ? RoutePaths.products
                  : assignParamsToUrl(RoutePaths.productsCategory, { slug: display(category.slug) })
              }
            >
              {display(category.name)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}

NavigationCategories.displayName = TAG

export default NavigationCategories
