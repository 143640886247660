import { useRef } from 'react'

/**
 * Similar to useMemo, but instead of an array of dependencies pass a comparator function.
 *
 * @example
 * ```ts
 * import isEqual from 'lodash/isEqual';
 * const memoizedObject = useMemoCompare(() => constantlyUpdatingObjectReference, isEqual);
 * ```
 */
function useMemoCompare<T>(factory: () => T, compare: (prevValue: T, nextValue: T) => boolean): T {
  const value = factory()
  const previousRef = useRef<T>(value)
  const shouldUpdate = !compare(previousRef.current, value)
  if (shouldUpdate) {
    previousRef.current = value
  }
  return previousRef.current
}

export default useMemoCompare
