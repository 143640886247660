import React from 'react'

import getLayoutMain, { LayoutMainProps } from 'components/layouts/Main'
import GuardNotAuthorized from 'components/modules/Auth/GuardNotAuthorized'

export interface LayoutNotAuthorizedProps extends LayoutMainProps {}

const LayoutNotAuthorized: LayoutComponent<LayoutNotAuthorizedProps> = (props, page) =>
  getLayoutMain(props)(<GuardNotAuthorized>{page}</GuardNotAuthorized>)

function getLayoutNotAuthorized(props: LayoutNotAuthorizedProps = {}): GetLayout {
  return (page) => LayoutNotAuthorized(props, page)
}

export default getLayoutNotAuthorized
