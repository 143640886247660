import Head from 'next/head'
import Script from 'next/script'
import React, { FunctionComponent } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'GoogleRecaptcha'

const GoogleRecaptcha: FunctionComponent = () => {
  const NEXT_PUBLIC_RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY

  if (!NEXT_PUBLIC_RECAPTCHA_KEY) {
    return null
  }

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://www.google.com/" crossOrigin="anonymous" />
      </Head>
      <Script
        id="google-recaptcha"
        strategy="afterInteractive"
        src={`https://www.google.com/recaptcha/api.js?render=${NEXT_PUBLIC_RECAPTCHA_KEY}`}
      />
    </>
  )
}

GoogleRecaptcha.displayName = TAG

export default GoogleRecaptcha
