import React, { FunctionComponent } from 'react'

import MobileMenuMainButton from 'components/modules/Header/MobileMenu/MobileMenuMainButton'
import MenuDrawerProvider from 'components/modules/Header/MobileMenu/Provider'

/**
 * Used for E2E tests.
 */
const TAG = 'MobileMenu'

const MobileMenu: FunctionComponent = () => {
  return (
    <MenuDrawerProvider>
      <MobileMenuMainButton />
    </MenuDrawerProvider>
  )
}

MobileMenu.displayName = TAG

export default MobileMenu
