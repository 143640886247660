import type { ThemeComponents } from '@chakra-ui/react'

import { breakpoints } from 'theme/foundations/breakpoints'

const mdMaxQuery = `@media screen and (max-width: ${breakpoints['md']})`
const mdMinQuery = `@media screen and (min-width: ${breakpoints['md']})`

/**
 * Override table component configuration.
 * @link https://chakra-ui.com/docs/components/table
 * @default `src\node_modules\@chakra-ui\theme\dist\components\table.js`
 */
export const Table: ThemeComponents['Table'] = {
  variants: {
    simple: {
      table: {
        position: 'relative',
      },
      thead: {
        [mdMaxQuery]: {
          visibility: 'hidden',
          height: 0,
          position: 'absolute',
        },
      },

      tr: {
        transition: 'background-color 0.1s',
        [[
          '&[data-link="true"]:is(:hover, :focus-visible)', // hover on row with link
          '&[data-link="true"]:focus-within:has(> td > a:focus-visible)', // focus on link in row
        ].join()]: {
          backgroundColor: 'gray.100',
        },
        '&[data-link="true"] a': {
          // Disable outline on link in row when row is linkable
          outline: 'none',
        },
        [mdMaxQuery]: {
          display: 'block',
          paddingBlock: '4',
          _even: {
            backgroundColor: 'gray.50',
          },
        },
      },
      th: {
        height: '8',
        paddingBlock: '2',
        paddingInline: '5',
        color: 'black',
        borderColor: 'black',
        borderBottomWidth: '2px',
        fontSize: 'sm',
        fontWeight: 'bold',
        lineHeight: '1.5',
        textTransform: 'initial',

        [mdMinQuery]: {
          _first: {
            paddingInlineStart: '0',
          },
        },
      },
      td: {
        height: '14',
        paddingBlock: '1',
        paddingInline: '5',
        borderColor: 'gray.300',
        [mdMinQuery]: {
          paddingBlock: '2',
          _first: {
            paddingInlineStart: '0',
          },
        },
        [mdMaxQuery]: {
          display: 'flex',
          border: 'none',
          height: 'auto',
          '&[data-label]': {
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          },
          '&[data-label]::before': {
            alignSelf: 'center',
            content: 'attr(data-label)',
            fontWeight: 'bold',
          },
        },
      },
    },
    striped: {
      tbody: {
        tr: {
          _even: {
            td: {
              backgroundColor: 'gray.50',
            },
          },
          _odd: {
            td: {
              backgroundColor: 'white',
            },
          },
        },
        td: {
          border: 'none',
          paddingBlock: '3',
          paddingInline: '2',
        },
      },
    },
  },
}
