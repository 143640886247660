import { Icon, SystemStyleInterpolation, Text, TextProps } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ShoppingListIcon from 'assets/add_list.svg?component'
import getMyShoppingLists from 'commercetools/shoppingLists/getMyShoppingLists'
import Link from 'components/elements/Link'
import { RoutePaths } from 'config/routes'
import useBlanketAgreements from 'utils/Providers/BlanketAgreements/useBlanketAgreements'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

/**
 * Used for E2E tests.
 */
const TAG = 'ShoppingListInfoButton'

const styles = {
  link: {
    color: 'primary.white',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '3',
    h: 'full',
  },
  icon: {
    boxSize: { base: '1rem', lg: '1.25rem' },
  },
  text: {
    display: { base: 'none', lg: 'block' },
    marginInlineStart: '3',
  },
} as const satisfies SystemStyleInterpolation

const ShoppingListInfoButton: FunctionComponent<TextProps> = (props) => {
  const { t } = useTranslation()

  const label = t('components.topbar.yourShoppingList')

  const businessUnit = useBusinessUnit()
  const blanketAgreement = useBlanketAgreements()
  const queryClient = useQueryClient()
  const prefetchShoppingLists = () =>
    queryClient.prefetchQuery(
      ['getMyShoppingLists', businessUnit.data.key, blanketAgreement.data.id, blanketAgreement.data.currency],
      getMyShoppingLists,
    )

  return (
    <Link
      {...styles.link}
      data-testid={TAG}
      rel="noreferrer"
      href={RoutePaths.shoppingLists}
      aria-label={label}
      onMouseOver={prefetchShoppingLists}
      onTouchStart={prefetchShoppingLists}
    >
      <Icon aria-hidden="true" as={ShoppingListIcon} {...styles.icon} />
      <Text {...styles.text} {...props}>
        {label}
      </Text>
    </Link>
  )
}

ShoppingListInfoButton.displayName = TAG

export default ShoppingListInfoButton
