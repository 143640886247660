import { Stack, List, ListItem } from '@chakra-ui/react'
import omit from 'lodash/omit'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import type { ChakraNextLinkProps } from 'components/elements/Link'
import Link from 'components/elements/Link'
import hasProperty from 'utils/hasProperty'

export type MenuLink = ChakraNextLinkProps & ({ translateKey: TranslationKeys['common'] } | { name: string })

interface MenuListProps {
  menu: ReadonlyArray<MenuLink>
}

const TAG = 'MenuList'

const MenuList: FunctionComponent<MenuListProps> = ({ menu, ...props }) => {
  const { t } = useTranslation()

  return (
    <Stack data-testid={TAG} as={List} {...props}>
      {menu?.map(({ href, ...link }) => (
        <ListItem key={href}>
          <Link href={href} {...omit(link, 'translateKey', 'name')} rel="noopener noreferrer">
            {hasProperty(link, 'translateKey') ? t(link.translateKey) : link.name}
          </Link>
        </ListItem>
      ))}
    </Stack>
  )
}

MenuList.displayName = TAG

export default MenuList
