import { Box, Button, Icon, Link, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import Trans from 'next-translate/Trans'
import React, { FunctionComponent } from 'react'

import EnvelopeIcon from 'assets/envelope.svg?component'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const TAG = 'ContactEmail'

const styles = {
  text: {
    fontSize: { base: 'sm', sm: 'md' },
    color: 'primary.blue',
  },
  button: {
    fontSize: 'inherit',
    textDecoration: 'underline',
  },
} as const satisfies SystemStyleInterpolation

const ContactEmail: FunctionComponent = () => {
  const businessUnit = useBusinessUnit()
  const { contactEmail } = businessUnit.data

  if (!contactEmail) {
    return null
  }

  return (
    <Box data-testid={TAG}>
      <Text {...styles.text}>
        <Trans
          i18nKey="components.header.contact"
          ns="cartCheckout"
          components={{
            b: <b />,
            Link: (
              <Button
                as={Link}
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${contactEmail}`}
                variant="link"
                {...styles.button}
                rightIcon={<Icon aria-hidden="true" boxSize="5" as={EnvelopeIcon} />}
              >
                {businessUnit.data.contactEmail}
              </Button>
            ),
          }}
        />
      </Text>
    </Box>
  )
}

ContactEmail.displayName = TAG

export default ContactEmail
